import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion';

const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
  }
  MuiAccordionSummary-content Mui-expanded {
    padding: 0;
  }

  && .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }

  && .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`

export default StyledAccordion;