import React from 'react';
import classes from './StatCard.module.scss';

const StatCard = (props) => {
  return (
    <div className={classes.StatCard}>
      <div className={classes.Image}>
        <img src={ props.imageUrl } alt='graph' />
      </div>
      <div className={classes.Stat}>
        <div className={classes.Value}>{ props.value }</div>
        <div className={classes.Label}>{ props.label }</div>
      </div>
    </div>
  )
}

export default StatCard;