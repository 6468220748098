import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalContext';
import logoImg from './assets/images/logo.svg';
import Home from './pages/Home/Home';
import GeoMap from './pages/GeoMap/GeoMap';
import About from './pages/About/About';
import Register from './pages/Register/Register';
import Partners from './pages/Partners/Partners';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import UserRoles from './services/user-roles.enum';
import { injectIntl } from 'react-intl';

function App(props) {
  const { intl } = props;
  const state = {
    header: {
      socialitems: [],
      menuitems: [
        {
          id: 0,
          label: intl.formatMessage({id: 'app.navbar.home', defaultMessage:''}),
          action: () => { window.location.href = '/'; },
        },
        {
          id: 1,
          label: intl.formatMessage({id: 'app.navbar.map', defaultMessage:''}),
          action: () => { window.location.href = '/map'; },
        },
        {
          id: 2,
          label: intl.formatMessage({id: 'app.navbar.agritechs', defaultMessage:''}),
          action: () => { window.location.href = '/map?type=AGRITECH'; }
        },
        {
          id: 3,
          label: intl.formatMessage({id: 'app.navbar.fintechs', defaultMessage:''}),
          action: () => { window.location.href = '/map?type=FINTECH'; }
        },
        {
          id: 4,
          label: intl.formatMessage({id: 'app.navbar.about', defaultMessage:''}),
          action: () => { window.location.href = '/about'; }
        },
        {
          id: 5,
          label: intl.formatMessage({id: 'app.navbar.partners', defaultMessage:''}),
          action: () => { window.location.href = '/partners'; }
        },
        {
          id: 6,
          label: intl.formatMessage({id: 'app.navbar.disclaimer', defaultMessage:''}),
          action: () => { window.location.href = '/disclaimer'; }
        }
      ],
      logo: logoImg
    },
    footer: {}
  };

  return (
    <GlobalProvider value={state}>
      <BrowserRouter>
        <Switch>
          <Route path='/'  exact component={Home} />
          <Route path='/map'  exact component={GeoMap} />
          <Route path='/about'  exact component={About} />
          <Route path='/register'  exact component={Register} />
          <Route path='/partners'  exact component={Partners} />
          <Route path='/disclaimer'  exact component={Disclaimer} />
          {/* <Route path='/company'  exact component={Register} /> */}
          <Route path='/login'  exact component={Login} />
          {/* <Route path='/dashboard'  exact component={Dashboard} /> */}
          <PrivateRoute path='/dashboard'  exact component={Dashboard} roles={[UserRoles.ADMIN]} />
          <PrivateRoute path='/company'  exact component={Register} roles={[UserRoles.ADMIN]} />
        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default injectIntl(App);
