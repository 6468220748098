import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import classes from './SearchInput.module.scss';
import { FormattedMessage } from 'react-intl';

const SearchBox = (props) => {
  return (
    <Paper className={classes.SearchBox}>
      <SearchIcon className={classes.Icon} />
      <InputBase
        inputRef={props.forwardedRef}
        className={classes.Input}
        placeholder={props.placeholder}
        inputProps={{ 'aria-label': 'buscar' }}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            props.onSearch()
          }
        }}
      />
      <Button className={classes.Button} onClick={props.onSearch} variant="contained" color="primary">
        <FormattedMessage id="app.map.button.search" defaultMessage="" />
      </Button>
    </Paper>
  );
}

export default SearchBox