import React from 'react'
import classes from './Details.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons"
import CloseIcon from '@material-ui/icons/Close';
import withSizes from 'react-sizes';
import logo from '../assets/images/geologo.svg';
import { FormattedMessage, injectIntl } from 'react-intl';

const Info = props => {
  let containerClass = classes.Info
  if (props.cols === 2) {
    containerClass = classes.Info2Cols
  }
  return (
    <div className={containerClass}>
      <div className={classes.Label}>{props.label}</div>
      <div className={classes.Value}>{props.value}</div>
    </div>
  )
}

const Details = props => {
  const { intl } = props;
  return (
    <div className={classes.Deatils}>
      <div className={classes.CloseContainer}><CloseIcon className={classes.Close} onClick={props.onClose}/></div>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <img src={props.data.logo ? props.data.logo.includes('base64') ? props.data.logo : `/images/logos/${props.data.logo}` : logo} alt='Logo' />
        </div>
        <div className={classes.Title}>{ props.data.name }</div>
        { props.isMobile ? null :
        <div className={classes.Social}>
          { props.data.facebook ? <FontAwesomeIcon onClick={()=> window.open(props.data.facebook, "_blank")} className={classes.SocialIcon} icon={faFacebookF}/> : null}
          { props.data.twitter ? <FontAwesomeIcon onClick={()=> window.open(props.data.twitter, "_blank")} className={classes.SocialIcon} icon={faTwitter}/> : null}
          { props.data.linkedin ? <FontAwesomeIcon onClick={()=> window.open(props.data.linkedin, "_blank")} className={classes.SocialIcon} icon={faLinkedinIn}/> : null}
          { props.data.instagram ? <FontAwesomeIcon onClick={()=> window.open(props.data.instagram, "_blank")} className={classes.SocialIcon} icon={faInstagram}/> : null}
        </div>
        }
      </div>
      <div className={classes.Body}>
        <div className={classes.Website}><a href={props.data.website} target="_blank">{props.data.website}</a></div>
        <div className={classes.Description}>{props.data.description}</div>
        { props.isMobile ? 
        <div className={classes.Social}>
          { props.data.facebook ? <FontAwesomeIcon onClick={()=> window.open(props.data.facebook, "_blank")} className={classes.SocialIcon} icon={faFacebookF}/> : null}
          { props.data.twitter ? <FontAwesomeIcon onClick={()=> window.open(props.data.twitter, "_blank")} className={classes.SocialIcon} icon={faTwitter}/> : null}
          { props.data.linkedin ? <FontAwesomeIcon onClick={()=> window.open(props.data.linkedin, "_blank")} className={classes.SocialIcon} icon={faLinkedinIn}/> : null}
          { props.data.instagram ? <FontAwesomeIcon onClick={()=> window.open(props.data.instagram, "_blank")} className={classes.SocialIcon} icon={faInstagram}/> : null}
        </div> : null
        }
        <Info label={intl.formatMessage({id: 'app.startup.name', defaultMessage: ''})} value={props.data.otherName} />
        <Info label={intl.formatMessage({id: 'app.startup.document', defaultMessage: ''})} value={props.data.companyIdentifier} />
        <Info cols={2} label={intl.formatMessage({id: 'app.startup.address', defaultMessage: ''})} value={props.data.address} />
        <Info label={intl.formatMessage({id: 'app.startup.phone', defaultMessage: ''})} value={props.data.phone} />
        <Info label={intl.formatMessage({id: 'app.startup.category', defaultMessage: ''})} value={intl.formatMessage({id: `app.map.filters.options.${props.data.category}`, defaultMessage: props.data.category})} />
        <Info label={intl.formatMessage({id: 'app.startup.manager', defaultMessage: ''})} value={props.data.ceo} />
        <Info label={intl.formatMessage({id: 'app.startup.association', defaultMessage: ''})} value={props.data.association} />
        <Info label={intl.formatMessage({id: 'app.startup.type', defaultMessage: ''})} value={props.data.type} />
        <div className={classes.Title}>
          <FormattedMessage id="app.startup.representation" defaultMessage="" />
        </div>
        <Info label={intl.formatMessage({id: 'app.startup.representation.email', defaultMessage: ''})} value={props.data.email} />
        <Info label={intl.formatMessage({id: 'app.startup.representation.phone', defaultMessage: ''})} value={props.data.phone} />
        <div className={classes.Title}>
          <FormattedMessage id="app.startup.services" defaultMessage="" />
        </div>
        {
          props.data.services.map((item, index) => {
            return <Info cols={2} label={null} value={intl.formatMessage({id: `app.map.filters.options.${item}`, defaultMessage: item})} />
          })
        }
      </div>
    </div>
  )
}

const mapSizesToProps = ({ width, height }) => ({
  isMobile: width <= 767,
  width: width,
  height: height
})

export default injectIntl(withSizes(mapSizesToProps)(Details));