import React from 'react';
import { Formik, Form } from 'formik';
import withSizes from 'react-sizes';
import { FormattedMessage, injectIntl } from 'react-intl';
import LocationPicker from 'react-location-picker';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import classes from './Register.module.scss';
import Layout from '../../components/Layout/SecundaryLayout';
import PrimaryButton from '../../components/Button/PrimaryButton';
import RadioInput from '../../components/Input/RadioInput';
import TextInput from '../../components/Input/TextInput';
import SelectInput from '../../components/Input/SelectInput';
import CheckboxInput from '../../components/Input/CheckboxInput';
import RegisterSchema from './Register.validate'
import StartupServices from '../../services/startups.services';
import * as QueryString from 'query-string';
import logo from '../../assets/images/geologo.svg';
import { CountryCodes } from '../../services/countrycodes';
import Modal from '../../components/Modal/Modal';
import RoomIcon from '@material-ui/icons/Room';

const defaultPosition = {
  lat: -12.043056467035347,
  lng: -77.04306315951456
};

const RegisterInformation = props => {
  const { intl } = props;
  const [startup, setStartup] = React.useState({});
  const [services, setServices] = React.useState([]);
  const [companyType, setCompanyType] = React.useState('FINTECH')
  const [error, setError] = React.useState(false);
  const [positionError, setPositionError] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [position, setPosition] = React.useState(defaultPosition);
  const [address, setAddress] = React.useState(null);
  const [logoImg, setLogoImg] = React.useState(null);
  
  const queries = QueryString.parse(props.location.search)

  React.useEffect(() => {
    if(queries['id']) {
      StartupServices.get(queries['id'])
      .then(res => {
        if (res.category) {
          const cats = res.category.split(',');
          res.mainCategory = cats[0];
          res.secundaryCategory = cats.length > 1 ? cats[1] : null;
        }
        setStartup(res);
        setServices(res.services ? res.services.split(',') : []);
        setAddress(res.address);
      })
      .catch(err => {
        window.location.href = '/dashboard'
      })
    }
  }, []);

  const handleSubmit = (values, {
    setSubmitting
  }) => {
    if (companyType === 'FINTECH' && services.length === 0) {
      setError(true)
      return
    }

    if (!address) {
      setPositionError(true)
      return
    }

    const data = {
      "type": values.type,
      "name": values.name,
      "description": values.description,
      "logo": values.logo,
      "company_identifier": values.document,
      "website": values.website,
      "ceo": values.manager,
      "ceo_email": values.managerEmail,
      "contact": values.contact,
      "contact_email": values.contactEmail,
      "contact_position": values.contactPosition,
      "country_code": values.country,
      "category": values.secundaryCategory ? `${values.mainCategory},${values.secundaryCategory}` : `${values.mainCategory}`,
      "has_women": values.women === 'Y',
      "women_proportion": values.womenNumber,
      "has_young": values.young === 'Y',
      "young_proportion": values.youngNumber,
      "has_rural": values.rural === 'Y',
      "rural_proportion": values.ruralNumber,
      "facebook": values.facebook,
      "linkedin": values.linkedin,
      "instagram": values.instagram,
      "services": services.join(),
      "status": values.status ? 'ACTIVE' : 'INACTIVE',
      "latitude": position.lat,
      "longitude": position.lng,
      "address": address
    }
    
    if (startup.id) {
      StartupServices.update(startup.id, data)
      .then(result => {
        setSubmitting(false);
        window.location.href = '/dashboard'
      })
      .catch(err => {
        setSubmitting(false);
      });
    } else {
      StartupServices.create(data)
      .then(result => {
        setSubmitting(false);
        window.location.href = '/'
      })
      .catch(err => {
        setSubmitting(false);
      });
    }
  }

  const addService = serv => {
    const aux = [...services]
    aux.push(serv);
    setServices(aux);
  }

  const removeService = serv => {
    const aux = [...services]
    const index = aux.indexOf(serv);
    if (index > -1) {
      aux.splice(index, 1);
    }
    setServices(aux);
  }

  const fileToBase64 = async (file) => {
    return new Promise(resolve => {
      // var file = new File([filename], filepath);
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      
      // Convert data to base64 
      reader.readAsDataURL(file);
    });
  };

  const closeModalHandler = () => {
    setShowModal(false);
  }

  const handleLocationChange = ({ position, address, places }) => {
    setPosition(position);
    setAddress(address);
  }

  return (
    <Layout image='/images/register.svg' onReturn={() => window.location.href =  startup.id ? '/dashboard' : '/' }>      
      <Formik 
        innerRef={props.forwardedRef}
        enableReinitialize={true}
        initialValues={{
          type: startup.type || 'FINTECH',
          name: startup.name || '',
          description: startup.description || '',
          logo: startup.logo || '',
          document: startup.companyIdentifier || '',
          website: startup.website || '',
          manager: startup.ceo || '',
          managerEmail: startup.email || '',
          contact: startup.contact || '',
          contactPosition: startup.contactPosition || '',
          contactEmail: startup.contactEmail || '',
          country: startup.countryCode || '',
          mainCategory: startup.mainCategory || '',
          secundaryCategory: startup.secundaryCategory || '',
          women: startup.hasWomen ? 'Y' : 'N',
          womenNumber: startup.womenProportion || 0,
          young: startup.hasYoung ? 'Y' : 'N',
          youngNumber: startup.youngProportion || 0,
          rural: startup.hasRural ? 'Y' : 'N',
          ruralNumber: startup.ruralProportion || 0,
          facebook: startup.facebook || '',
          linkedin: startup.linkedin || '',
          twitter: startup.twitter || '',
          instagram: startup.instagram || '',
          status: startup.status ? startup.status === 'ACTIVE' : false
        }}
        validationSchema={RegisterSchema}
        onSubmit={handleSubmit}>
        { formProps => {
          let textareaStyles = {width: '100%'}
          if (formProps.errors.description) {
            textareaStyles = {width: '100%', border: '2px solid red'}
          }
          return (
            <Form>
              <div className={classes.Container}>
                <div className={classes.Title}>
                  { 
                    startup.name ? 
                    <div>
                      { startup.name }
                      <Switch
                        checked={formProps.values.status}
                        onChange={(event) => formProps.setFieldValue('status', event.target.checked)}
                        color="primary"
                        name="status"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </div> : 
                    intl.formatMessage({id: 'app.startup.register.title', defaultMessage: ''})
                  }
                </div>
                <div className={classes.Body}>
                  { startup.id ?
                  <section>
                    <div className={classes.FormElement}>
                      <div className={classes.Logo}>
                        {formProps.values.logo ? 
                          <img className={classes.Logo} src={`${formProps.values.logo}`} alt='logo' /> :
                          <img className={classes.Logo} src={logo} alt='logo' />
                        }
                      </div>
                      <div className={classes.Logo}>
                        <Button variant="contained" component="label">
                          <FormattedMessage id="app.startup.register.change" defaultMessage="" />
                          <input type="file" hidden onChange={e => fileToBase64(e.target.files[0]).then(r => formProps.setFieldValue('logo', r))} />
                        </Button>
                      </div>
                    </div>
                  </section> : null
                  }
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.type" defaultMessage="" />
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}></div>
                      <div>
                        <RadioInput
                          key='type' 
                          id='type' 
                          name='type'
                          value={ formProps.values.type }
                          values={[{ key: 'FINTECH', value: 'Fintech'}, {key: 'AGRITECH', value: 'Agritech' }]} 
                          onChange={(event) => { setCompanyType(event.target.value); formProps.setFieldValue('type', event.target.value); }}
                          error={ formProps.errors.type }
                          disabled={props.disabled} />
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.general.info" defaultMessage="" />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='name' 
                        id='name' 
                        label={intl.formatMessage({id: 'app.startup.register.name', defaultMessage: ''})}
                        name='name'
                        placeholder={intl.formatMessage({id: 'app.startup.register.name.placeholder', defaultMessage: ''})}
                        value={ formProps.values.name }
                        onChange={(event) => formProps.setFieldValue('name', event.target.value)}
                        error={ formProps.errors.name }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='document' 
                        id='document' 
                        label={intl.formatMessage({id: 'app.startup.register.document', defaultMessage: ''})}
                        name='document'
                        placeholder={intl.formatMessage({id: 'app.startup.register.document.placeholder', defaultMessage: ''})}
                        value={ formProps.values.document }
                        onChange={(event) => formProps.setFieldValue('document', event.target.value)}
                        error={ formProps.errors.document }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='website' 
                        id='website' 
                        label={intl.formatMessage({id: 'app.startup.register.website', defaultMessage: ''})}
                        name='website'
                        placeholder={intl.formatMessage({id: 'app.startup.register.website.placeholder', defaultMessage: ''})}
                        value={ formProps.values.website }
                        onChange={(event) => formProps.setFieldValue('website', event.target.value)}
                        error={ formProps.errors.website }
                        disabled={props.disabled} />
                    </div>
                    <div></div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='manager' 
                        id='manager' 
                        label={intl.formatMessage({id: 'app.startup.register.manager', defaultMessage: ''})}
                        name='manager'
                        placeholder={intl.formatMessage({id: 'app.startup.register.manager.placeholder', defaultMessage: ''})}
                        value={ formProps.values.manager }
                        onChange={(event) => formProps.setFieldValue('manager', event.target.value)}
                        error={ formProps.errors.manager }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='managerEmail' 
                        id='managerEmail' 
                        label={intl.formatMessage({id: 'app.startup.register.manager.email', defaultMessage: ''})}
                        name='managerEmail'
                        placeholder={intl.formatMessage({id: 'app.startup.register.manager.email.placeholder', defaultMessage: ''})}
                        inputProps={{type: 'email'}}
                        value={ formProps.values.managerEmail }
                        onChange={(event) => formProps.setFieldValue('managerEmail', event.target.value)}
                        error={ formProps.errors.managerEmail }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='contact' 
                        id='contact' 
                        label={intl.formatMessage({id: 'app.startup.register.contact', defaultMessage: ''})}
                        name='contact'
                        placeholder={intl.formatMessage({id: 'app.startup.register.contact.placeholder', defaultMessage: ''})}
                        value={ formProps.values.contact }
                        onChange={(event) => formProps.setFieldValue('contact', event.target.value)}
                        error={ formProps.errors.contact }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='contactPosition' 
                        id='contactPosition' 
                        label={intl.formatMessage({id: 'app.startup.register.contact.position', defaultMessage: ''})}
                        name='contactPosition'
                        placeholder={intl.formatMessage({id: 'app.startup.register.contact.position.placeholder', defaultMessage: ''})}
                        value={ formProps.values.contactPosition }
                        onChange={(event) => formProps.setFieldValue('contactPosition', event.target.value)}
                        error={ formProps.errors.contactPosition }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='contactEmail' 
                        id='contactEmail' 
                        label={intl.formatMessage({id: 'app.startup.register.contact.email', defaultMessage: ''})}
                        name='contactEmail'
                        placeholder={intl.formatMessage({id: 'app.startup.register.contact.email.placeholder', defaultMessage: ''})}
                        inputProps={{type: 'email'}}
                        value={ formProps.values.contactEmail }
                        onChange={(event) => formProps.setFieldValue('contactEmail', event.target.value)}
                        error={ formProps.errors.contactEmail }
                        disabled={props.disabled} />
                    </div>
                  </section>
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.location" defaultMessage="" />
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='country'
                        id='country'
                        label={intl.formatMessage({id: 'app.startup.register.country', defaultMessage: ''})}
                        name='country'
                        value={ formProps.values.country }
                        values={CountryCodes} 
                        onChange={(event) => formProps.setFieldValue('country', event.target.value)}
                        error={ formProps.errors.country }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.location.main" defaultMessage="" /> {positionError ? <span className={classes.Error}><FormattedMessage id="app.startup.register.location.main.select" defaultMessage="" /></span> : null }
                      </div>
                      <div className={classes.Position} onClick={() => setShowModal(true)}>
                        <RoomIcon /> { address ? address : intl.formatMessage({id: 'app.startup.register.location.add', defaultMessage: ''}) }
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.info" defaultMessage="" /> { companyType }
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='mainCategory'
                        id='mainCategory'
                        label={intl.formatMessage({id: 'app.startup.register.category.main', defaultMessage: ''})}
                        name='mainCategory'
                        value={ formProps.values.mainCategory }
                        values={[
                          { key: 'CGP000', value: intl.formatMessage({id: 'app.startup.register.categories.select', defaultMessage: ''}) },
                          { key: "CGP003", value: intl.formatMessage({id: 'app.map.filters.options.CGP003', defaultMessage: ''}) },
                          { key: "CGP009", value: intl.formatMessage({id: 'app.map.filters.options.CGP009', defaultMessage: ''}) },
                          { key: "CGP005", value: intl.formatMessage({id: 'app.map.filters.options.CGP005', defaultMessage: ''}) },
                          { key: "CGP002", value: intl.formatMessage({id: 'app.map.filters.options.CGP002', defaultMessage: ''}) },
                          { key: "CGP010", value: intl.formatMessage({id: 'app.map.filters.options.CGP010', defaultMessage: ''}) },
                          { key: "CGP006", value: intl.formatMessage({id: 'app.map.filters.options.CGP006', defaultMessage: ''}) },
                          { key: "CGP008", value: intl.formatMessage({id: 'app.map.filters.options.CGP008', defaultMessage: ''}) },
                          { key: "CGP004", value: intl.formatMessage({id: 'app.map.filters.options.CGP004', defaultMessage: ''}) },
                          { key: "CGP007", value: intl.formatMessage({id: 'app.map.filters.options.CGP007', defaultMessage: ''}) },
                          { key: "CGP001", value: intl.formatMessage({id: 'app.map.filters.options.CGP001', defaultMessage: ''}) },
                          { key: "CGP011", value: intl.formatMessage({id: 'app.map.filters.options.CGP011', defaultMessage: ''}) },
                          { key: "CGP021", value: intl.formatMessage({id: 'app.map.filters.options.CGP021', defaultMessage: ''}) },
                          { key: "CGP022", value: intl.formatMessage({id: 'app.map.filters.options.CGP022', defaultMessage: ''}) },
                          { key: "CGP023", value: intl.formatMessage({id: 'app.map.filters.options.CGP023', defaultMessage: ''}) },
                          { key: "CGP024", value: intl.formatMessage({id: 'app.map.filters.options.CGP024', defaultMessage: ''}) },
                          { key: "CGP025", value: intl.formatMessage({id: 'app.map.filters.options.CGP025', defaultMessage: ''}) },
                          { key: "CGP026", value: intl.formatMessage({id: 'app.map.filters.options.CGP026', defaultMessage: ''}) },
                          { key: "CGP027", value: intl.formatMessage({id: 'app.map.filters.options.CGP027', defaultMessage: ''}) },
                          { key: "CGP028", value: intl.formatMessage({id: 'app.map.filters.options.CGP028', defaultMessage: ''}) },
                          { key: "CGP029", value: intl.formatMessage({id: 'app.map.filters.options.CGP029', defaultMessage: ''}) },
                          { key: "CGP030", value: intl.formatMessage({id: 'app.map.filters.options.CGP030', defaultMessage: ''}) },
                          { key: "CGP031", value: intl.formatMessage({id: 'app.map.filters.options.CGP031', defaultMessage: ''}) }
                        ]} 
                        onChange={(event) => formProps.setFieldValue('mainCategory', event.target.value)}
                        error={ formProps.errors.mainCategory }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='secundaryCategory'
                        id='secundaryCategory'
                        label={intl.formatMessage({id: 'app.startup.register.category.secundary', defaultMessage: ''})}
                        name='secundaryCategory'
                        value={ formProps.values.secundaryCategory }
                        values={[
                          { key: 'CGP000', value: intl.formatMessage({id: 'app.startup.register.categories.select', defaultMessage: ''}) },
                          { key: "CGP003", value: intl.formatMessage({id: 'app.map.filters.options.CGP003', defaultMessage: ''}) },
                          { key: "CGP009", value: intl.formatMessage({id: 'app.map.filters.options.CGP009', defaultMessage: ''}) },
                          { key: "CGP005", value: intl.formatMessage({id: 'app.map.filters.options.CGP005', defaultMessage: ''}) },
                          { key: "CGP002", value: intl.formatMessage({id: 'app.map.filters.options.CGP002', defaultMessage: ''}) },
                          { key: "CGP010", value: intl.formatMessage({id: 'app.map.filters.options.CGP010', defaultMessage: ''}) },
                          { key: "CGP006", value: intl.formatMessage({id: 'app.map.filters.options.CGP006', defaultMessage: ''}) },
                          { key: "CGP008", value: intl.formatMessage({id: 'app.map.filters.options.CGP008', defaultMessage: ''}) },
                          { key: "CGP004", value: intl.formatMessage({id: 'app.map.filters.options.CGP004', defaultMessage: ''}) },
                          { key: "CGP007", value: intl.formatMessage({id: 'app.map.filters.options.CGP007', defaultMessage: ''}) },
                          { key: "CGP001", value: intl.formatMessage({id: 'app.map.filters.options.CGP001', defaultMessage: ''}) },
                          { key: "CGP011", value: intl.formatMessage({id: 'app.map.filters.options.CGP011', defaultMessage: ''}) },
                          { key: "CGP021", value: intl.formatMessage({id: 'app.map.filters.options.CGP021', defaultMessage: ''}) },
                          { key: "CGP022", value: intl.formatMessage({id: 'app.map.filters.options.CGP022', defaultMessage: ''}) },
                          { key: "CGP023", value: intl.formatMessage({id: 'app.map.filters.options.CGP023', defaultMessage: ''}) },
                          { key: "CGP024", value: intl.formatMessage({id: 'app.map.filters.options.CGP024', defaultMessage: ''}) },
                          { key: "CGP025", value: intl.formatMessage({id: 'app.map.filters.options.CGP025', defaultMessage: ''}) },
                          { key: "CGP026", value: intl.formatMessage({id: 'app.map.filters.options.CGP026', defaultMessage: ''}) },
                          { key: "CGP027", value: intl.formatMessage({id: 'app.map.filters.options.CGP027', defaultMessage: ''}) },
                          { key: "CGP028", value: intl.formatMessage({id: 'app.map.filters.options.CGP028', defaultMessage: ''}) },
                          { key: "CGP029", value: intl.formatMessage({id: 'app.map.filters.options.CGP029', defaultMessage: ''}) },
                          { key: "CGP030", value: intl.formatMessage({id: 'app.map.filters.options.CGP030', defaultMessage: ''}) },
                          { key: "CGP031", value: intl.formatMessage({id: 'app.map.filters.options.CGP031', defaultMessage: ''}) }
                        ]}  
                        onChange={(event) => formProps.setFieldValue('secundaryCategory', event.target.value)}
                        error={ formProps.errors.secundaryCategory }
                        disabled={props.disabled} />
                    </div>
                    { startup.id ? null :
                    <div className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.logo" defaultMessage="" /><br/>
                        {
                          formProps.values.logo ?
                          <img className={classes.Logo} src={`${formProps.values.logo}`} alt='logo' /> : null
                        }
                      </div>
                      <div>
                        <Button variant="contained" component="label">
                          <FormattedMessage id="app.startup.register.logo.select" defaultMessage="" />
                          <input type="file" hidden onChange={e => fileToBase64(e.target.files[0]).then(r => formProps.setFieldValue('logo', r))} />
                        </Button>
                        {
                          formProps.errors.logo ? 
                          <p className={classes.Required}><FormattedMessage id="app.startup.register.required" defaultMessage="Campo obligatorio" /></p> : 
                          null
                        }
                      </div>
                    </div>
                    }
                    <div></div>
                    { companyType === 'FINTECH' ?
                    <div style={props.isMobile ? {} : {gridColumn: '1 / span 2'}} className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.services" defaultMessage="" /> {error ? <span className={classes.Error}>Selecciona al menos un servicio</span> : null }
                      </div>
                      <div className={classes.TwoColumn}>
                        <CheckboxInput 
                          key='1'
                          checked={services.includes('SGP001')}
                          disabled={!services.includes('SGP001') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP001') : removeService('SGP001') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP001', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='2'
                          checked={services.includes('SGP013')}
                          disabled={!services.includes('SGP013') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP013') : removeService('SGP013') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP013', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='3'
                          checked={services.includes('SGP002')}
                          disabled={!services.includes('SGP002') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP002') : removeService('SGP002') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP002', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='4'
                          checked={services.includes('SGP014')}
                          disabled={!services.includes('SGP014') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP014') : removeService('SGP014') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP014', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='5'
                          checked={services.includes('SGP003')}
                          disabled={!services.includes('SGP003') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP003') : removeService('SGP003') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP003', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='6'
                          checked={services.includes('SGP015')}
                          disabled={!services.includes('SGP015') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP015') : removeService('SGP015') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP015', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='7'
                          checked={services.includes('SGP004')}
                          disabled={!services.includes('SGP004') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP004') : removeService('SGP004') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP004', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='8'
                          checked={services.includes('SGP016')}
                          disabled={!services.includes('SGP016') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP016') : removeService('SGP016') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP016', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='9'
                          checked={services.includes('SGP005')}
                          disabled={!services.includes('SGP005') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP005') : removeService('SGP005') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP005', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='10'
                          checked={services.includes('SGP017')}
                          disabled={!services.includes('SGP017') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP017') : removeService('SGP017') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP017', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='11'
                          checked={services.includes('SGP006')}
                          disabled={!services.includes('SGP006') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP006') : removeService('SGP006') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP006', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='12'
                          checked={services.includes('SGP018')}
                          disabled={!services.includes('SGP018') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP018') : removeService('SGP018') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP018', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='13'
                          checked={services.includes('SGP007')}
                          disabled={!services.includes('SGP007') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP007') : removeService('SGP007') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP007', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='14'
                          checked={services.includes('SGP019')}
                          disabled={!services.includes('SGP019') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP019') : removeService('SGP019') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP019', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='15'
                          checked={services.includes('SGP008')}
                          disabled={!services.includes('SGP008') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP008') : removeService('SGP008') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP008', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='16'
                          checked={services.includes('SGP020')}
                          disabled={!services.includes('SGP020') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP020') : removeService('SGP020') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP020', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='17'
                          checked={services.includes('SGP009')}
                          disabled={!services.includes('SGP009') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP009') : removeService('SGP009') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP009', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='18'
                          checked={services.includes('SGP021')}
                          disabled={!services.includes('SGP021') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP021') : removeService('SGP021') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP021', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='19'
                          checked={services.includes('SGP010')}
                          disabled={!services.includes('SGP010') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP010') : removeService('SGP010') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP010', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='20'
                          checked={services.includes('SGP022')}
                          disabled={!services.includes('SGP022') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP022') : removeService('SGP022') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP022', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='21'
                          checked={services.includes('SGP011')}
                          disabled={!services.includes('SGP011') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP011') : removeService('SGP011') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP011', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='22'
                          checked={services.includes('SGP023')}
                          disabled={!services.includes('SGP023') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP023') : removeService('SGP023') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP023', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='23'
                          checked={services.includes('SGP012')}
                          disabled={!services.includes('SGP012') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP012') : removeService('SGP012') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP012', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='24'
                          checked={services.includes('SGP024')}
                          disabled={!services.includes('SGP024') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP024') : removeService('SGP024') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP024', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='25'
                          checked={services.includes('SGP025')}
                          disabled={!services.includes('SGP025') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP025') : removeService('SGP025') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP025', defaultMessage: ''})} />
                        <CheckboxInput 
                          key='26'
                          checked={services.includes('SGP026')}
                          disabled={!services.includes('SGP026') && services.length >= 3}
                          onChange={(event) => { event.target.checked ? addService('SGP026') : removeService('SGP026') }} 
                          name='service' 
                          label={intl.formatMessage({id: 'app.startup.register.services.SGP026', defaultMessage: ''})} />
                      </div>
                    </div>
                    : null }
                    <div style={props.isMobile ? {} : {gridColumn: '1 / span 2'}} className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.description" defaultMessage="" />
                      </div>
                      <div>
                        <textarea 
                          name='description' 
                          style={textareaStyles} 
                          rows='6' 
                          onChange={(event) => formProps.setFieldValue('description', event.target.value)} ></textarea>
                        {
                          formProps.errors.description ? 
                          <p className={classes.Required}><FormattedMessage id="app.startup.register.required" defaultMessage="Campo obligatorio" /></p> : 
                          null
                        }
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.demographic" defaultMessage="" />
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.demographic.women" defaultMessage="" />
                      </div>
                      <div>
                        <RadioInput
                          key='women' 
                          id='women' 
                          name='women'
                          value={ formProps.values.women }
                          values={[{ key: 'Y', value: intl.formatMessage({id: 'app.map.filters.options.Si', defaultMessage: ''})}, {key: 'N', value: intl.formatMessage({id: 'app.map.filters.options.No', defaultMessage: ''}) }]} 
                          onChange={(event) => formProps.setFieldValue('women', event.target.value)}
                          error={ formProps.errors.women }
                          disabled={props.disabled} />
                      </div>
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='womenNumber'
                        id='womenNumber'
                        label={intl.formatMessage({id: 'app.startup.register.demographic.women.porcentage', defaultMessage: ''})}
                        name='womenNumber'
                        value={ formProps.values.womenNumber }
                        values={[
                          { key: '0', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.0', defaultMessage: ''}) }, 
                          { key: '10', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.10', defaultMessage: ''}) }, 
                          { key: '29', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.29', defaultMessage: ''}) }, 
                          { key: '49', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.49', defaultMessage: ''}) },
                          { key: '75', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.75', defaultMessage: ''}) },
                          { key: '100', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.100', defaultMessage: ''}) },
                        ]} 
                        onChange={(event) => formProps.setFieldValue('womenNumber', event.target.value)}
                        error={ formProps.errors.womenNumber }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.demographic.young" defaultMessage="" />
                      </div>
                      <div>
                        <RadioInput
                          key='young' 
                          id='young' 
                          name='young'
                          value={ formProps.values.young }
                          values={[{ key: 'Y', value: 'Si'}, {key: 'N', value: 'No' }]} 
                          onChange={(event) => formProps.setFieldValue('young', event.target.value)}
                          error={ formProps.errors.young }
                          disabled={props.disabled} />
                      </div>
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='youngNumber'
                        id='youngNumber'
                        label={intl.formatMessage({id: 'app.startup.register.demographic.young.porcentage', defaultMessage: ''})}
                        name='youngNumber'
                        value={ formProps.values.youngNumber }
                        values={[
                          { key: '0', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.0', defaultMessage: ''}) }, 
                          { key: '10', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.10', defaultMessage: ''}) }, 
                          { key: '29', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.29', defaultMessage: ''}) }, 
                          { key: '49', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.49', defaultMessage: ''}) },
                          { key: '75', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.75', defaultMessage: ''}) },
                          { key: '100', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.100', defaultMessage: ''}) },
                        ]} 
                        onChange={(event) => formProps.setFieldValue('youngNumber', event.target.value)}
                        error={ formProps.errors.youngNumber }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}>
                        <FormattedMessage id="app.startup.register.demographic.rural" defaultMessage="" />
                      </div>
                      <div>
                        <RadioInput
                          key='rural' 
                          id='rural' 
                          name='rural'
                          value={ formProps.values.rural }
                          values={[{ key: 'Y', value: 'Si'}, {key: 'N', value: 'No' }]} 
                          onChange={(event) => formProps.setFieldValue('rural', event.target.value)}
                          error={ formProps.errors.rural }
                          disabled={props.disabled} />
                      </div>
                    </div>
                    <div className={classes.FormElement}>
                      <SelectInput 
                        key='ruralNumber'
                        id='ruralNumber'
                        label={intl.formatMessage({id: 'app.startup.register.demographic.rural.porcentage', defaultMessage: ''})}
                        name='ruralNumber'
                        value={ formProps.values.ruralNumber }
                        values={[
                          { key: '0', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.0', defaultMessage: ''}) }, 
                          { key: '10', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.10', defaultMessage: ''}) }, 
                          { key: '29', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.29', defaultMessage: ''}) }, 
                          { key: '49', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.49', defaultMessage: ''}) },
                          { key: '75', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.75', defaultMessage: ''}) },
                          { key: '100', value: intl.formatMessage({id: 'app.startup.register.demographic.porcentage.100', defaultMessage: ''}) },
                        ]} 
                        onChange={(event) => formProps.setFieldValue('ruralNumber', event.target.value)}
                        error={ formProps.errors.ruralNumber }
                        disabled={props.disabled} />
                    </div>
                  </section>
                  <section>
                    <div className={classes.Title}>
                      <FormattedMessage id="app.startup.register.social" defaultMessage="" />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='facebook' 
                        id='facebook' 
                        label={intl.formatMessage({id: 'app.startup.register.social.facebook', defaultMessage: ''})}
                        name='facebook'
                        placeholder={intl.formatMessage({id: 'app.startup.register.social.facebook.placeholder', defaultMessage: ''})}
                        value={ formProps.values.facebook }
                        onChange={(event) => formProps.setFieldValue('facebook', event.target.value)}
                        error={ formProps.errors.facebook }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='linkedin' 
                        id='linkedin' 
                        label={intl.formatMessage({id: 'app.startup.register.social.linkedin', defaultMessage: ''})}
                        name='linkedin'
                        placeholder={intl.formatMessage({id: 'app.startup.register.social.linkedin.placeholder', defaultMessage: ''})}
                        value={ formProps.values.linkedin }
                        onChange={(event) => formProps.setFieldValue('linkedin', event.target.value)}
                        error={ formProps.errors.linkedin }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='twitter' 
                        id='twitter' 
                        label={intl.formatMessage({id: 'app.startup.register.social.twitter', defaultMessage: ''})}
                        name='twitter'
                        placeholder={intl.formatMessage({id: 'app.startup.register.social.twitter.placeholder', defaultMessage: ''})}
                        value={ formProps.values.twitter }
                        onChange={(event) => formProps.setFieldValue('twitter', event.target.value)}
                        error={ formProps.errors.twitter }
                        disabled={props.disabled} />
                    </div>
                    <div className={classes.FormElement}>
                      <TextInput 
                        key='instagram' 
                        id='instagram' 
                        label={intl.formatMessage({id: 'app.startup.register.social.instagram', defaultMessage: ''})}
                        name='instagram'
                        placeholder={intl.formatMessage({id: 'app.startup.register.social.instagram.placeholder', defaultMessage: ''})}
                        value={ formProps.values.instagram }
                        onChange={(event) => formProps.setFieldValue('instagram', event.target.value)}
                        error={ formProps.errors.instagram }
                        disabled={props.disabled} />
                    </div>
                  </section>
                </div>
                <div className={classes.Footer}>
                  <PrimaryButton type='submit'>
                    {startup.id ? intl.formatMessage({id: 'app.startup.register.save', defaultMessage: ''}) : intl.formatMessage({id: 'app.startup.register.register', defaultMessage: ''})}
                  </PrimaryButton>
                </div>
              </div>
            </Form>
            );
          }}
      </Formik>
      <Modal show={showModal} onModalClose={closeModalHandler} width={500} >
        <LocationPicker
          containerElement={ <div style={ {height: 'calc(100% - 60px)'} } /> }
          mapElement={ <div style={ {height: props.isMobile ? '100%' : '400px'} } /> }
          defaultPosition={defaultPosition}
          onChange={handleLocationChange}
        />
        <div className={classes.ModalFooter}>
          <PrimaryButton onClick={() => setShowModal(false)}>
            <FormattedMessage id="app.startup.register.continue" defaultMessage="" />
          </PrimaryButton>
        </div>
      </Modal>
    </Layout>
  )
}

const mapSizesToProps = ({ width, height }) => ({
  isMobile: width <= 767,
  width: width,
  height: height
})

export default injectIntl(withSizes(mapSizesToProps)(RegisterInformation));