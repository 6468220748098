import React from 'react';
import classes from './Footer.module.scss';
import { GlobalConsumer } from '../../context/GlobalContext';
import { FormattedMessage } from 'react-intl';

const Footer = (props) => {
  return (
    <div className={classes.Footer}>
      <GlobalConsumer>
        { context => (
            <div className={classes.Footer}>
              <FormattedMessage id="app.footer.message" defaultMessage="" />
            </div>
          )
        }
      </GlobalConsumer>
    </div>
  )
}

export default Footer;