exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Home_CarouselContainer__1xfQx {\n  width: 100vw;\n  min-width: 1024px;\n  max-width: 1324px;\n  padding: 10px 0;\n  justify-self: center; }\n  @media only screen and (max-width: 767px) {\n    .Home_CarouselContainer__1xfQx {\n      min-width: 100%;\n      max-width: 100%; } }\n\n.Home_MainBanner__3CUe0 {\n  height: 620px;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr; }\n  @media only screen and (max-width: 767px) {\n    .Home_MainBanner__3CUe0 {\n      padding: 15px;\n      box-sizing: border-box; } }\n  .Home_MainBanner__3CUe0 .Home_Title__wC1E3 {\n    font-size: 36px;\n    justify-self: center;\n    align-self: flex-end; }\n    @media only screen and (max-width: 767px) {\n      .Home_MainBanner__3CUe0 .Home_Title__wC1E3 {\n        font-size: 28px; } }\n  .Home_MainBanner__3CUe0 .Home_Description__3UOFq {\n    justify-self: center;\n    align-self: center;\n    font-size: 18px;\n    color: #2E384D;\n    padding: 10px 0; }\n    @media only screen and (max-width: 767px) {\n      .Home_MainBanner__3CUe0 .Home_Description__3UOFq {\n        font-size: 16px; } }\n    .Home_MainBanner__3CUe0 .Home_Description__3UOFq div {\n      width: 1024px;\n      text-align: center; }\n      @media only screen and (max-width: 767px) {\n        .Home_MainBanner__3CUe0 .Home_Description__3UOFq div {\n          width: 100%; } }\n  .Home_MainBanner__3CUe0 .Home_Button__2jeoS {\n    display: grid;\n    justify-items: center;\n    align-items: center; }\n  .Home_MainBanner__3CUe0 .Home_Stats__26Eeo {\n    justify-self: center;\n    width: 1024px;\n    padding: 25px 0;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    justify-items: center; }\n    @media only screen and (max-width: 767px) {\n      .Home_MainBanner__3CUe0 .Home_Stats__26Eeo {\n        width: 100%; } }\n", ""]);

// Exports
exports.locals = {
	"CarouselContainer": "Home_CarouselContainer__1xfQx",
	"MainBanner": "Home_MainBanner__3CUe0",
	"Title": "Home_Title__wC1E3",
	"Description": "Home_Description__3UOFq",
	"Button": "Home_Button__2jeoS",
	"Stats": "Home_Stats__26Eeo"
};