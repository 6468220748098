exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Dashboard_Dashboard__2kB1y {\n  height: 100%;\n  padding: 40px; }\n  .Dashboard_Dashboard__2kB1y .Dashboard_Title__1pN3E {\n    font-weight: 300;\n    font-size: 32px;\n    line-height: 48px; }\n  .Dashboard_Dashboard__2kB1y .Dashboard_Options__Z9vnn {\n    padding: 10px 0;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr); }\n    @media only screen and (max-width: 767px) {\n      .Dashboard_Dashboard__2kB1y .Dashboard_Options__Z9vnn {\n        grid-template-columns: 1fr; } }\n  .Dashboard_Dashboard__2kB1y .Dashboard_Body__3dBDi {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-row-gap: 15px;\n    row-gap: 15px; }\n    .Dashboard_Dashboard__2kB1y .Dashboard_Body__3dBDi .Dashboard_Header__1Cpeo {\n      padding: 15px;\n      display: grid;\n      grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1fr;\n      align-items: center;\n      font-weight: 600;\n      font-size: 12px;\n      line-height: 16px; }\n    .Dashboard_Dashboard__2kB1y .Dashboard_Body__3dBDi .Dashboard_Row__1yVVv {\n      padding: 20px 15px;\n      display: grid;\n      grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1fr;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 20px;\n      background-color: #fff;\n      border-radius: 5px; }\n  .Dashboard_Dashboard__2kB1y .Dashboard_Footer__2Zur2 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    padding: 15px 0; }\n", ""]);

// Exports
exports.locals = {
	"Dashboard": "Dashboard_Dashboard__2kB1y",
	"Title": "Dashboard_Title__1pN3E",
	"Options": "Dashboard_Options__Z9vnn",
	"Body": "Dashboard_Body__3dBDi",
	"Header": "Dashboard_Header__1Cpeo",
	"Row": "Dashboard_Row__1yVVv",
	"Footer": "Dashboard_Footer__2Zur2"
};