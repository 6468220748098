exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Card_Card__1tvLU {\n  background-color: #FFF;\n  display: grid;\n  grid-template-columns: 180px auto;\n  margin: 15px 0;\n  padding: 25px;\n  box-sizing: border-box;\n  height: 170px;\n  border-radius: 10px;\n  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */\n  /* Firefox 3.5 - 3.6 */\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);\n  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */ }\n  @media only screen and (max-width: 767px) {\n    .Card_Card__1tvLU {\n      grid-template-columns: 60px auto;\n      padding: 15px;\n      margin: 5px 0;\n      width: calc(100vw - 20px); } }\n  .Card_Card__1tvLU img {\n    width: 100%; }\n\n.Card_Logo__1ABou {\n  display: grid;\n  justify-items: center;\n  align-items: center; }\n\n.Card_Information__D16ZQ {\n  padding-left: 15px;\n  box-sizing: border-box;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 25px 16px auto 25px; }\n\n.Card_Header__1nXjb {\n  display: grid;\n  grid-template-columns: auto 100px; }\n\n.Card_Title__2YK6I {\n  color: #333333;\n  font-weight: 600;\n  font-size: 16px; }\n  @media only screen and (max-width: 767px) {\n    .Card_Title__2YK6I {\n      font-size: 14px; } }\n\n.Card_Tag__2_o7j {\n  justify-self: end;\n  color: #003870; }\n  @media only screen and (max-width: 767px) {\n    .Card_Tag__2_o7j {\n      font-size: 12px; } }\n\n.Card_Address__2tt9E {\n  color: #434548; }\n\n.Card_Website__29yLA {\n  color: #003870;\n  font-size: 12px;\n  overflow-wrap: anywhere; }\n  .Card_Website__29yLA a {\n    color: #003870;\n    font-weight: 600;\n    text-decoration: none; }\n\n.Card_More__1Ve9t {\n  display: grid;\n  grid-template-columns: auto 24px;\n  align-items: center;\n  justify-items: end;\n  text-transform: uppercase;\n  font-weight: 600; }\n\n.Card_Clickable__339j6 {\n  cursor: pointer; }\n", ""]);

// Exports
exports.locals = {
	"Card": "Card_Card__1tvLU",
	"Logo": "Card_Logo__1ABou",
	"Information": "Card_Information__D16ZQ",
	"Header": "Card_Header__1nXjb",
	"Title": "Card_Title__2YK6I",
	"Tag": "Card_Tag__2_o7j",
	"Address": "Card_Address__2tt9E",
	"Website": "Card_Website__29yLA",
	"More": "Card_More__1Ve9t",
	"Clickable": "Card_Clickable__339j6"
};