module.exports = {
  CountryCodes: [
    {
      "key": " ",
      "value": "Seleccionar..."
    },
    {
      "key": "AF",
      "value": "AFGHANISTAN"
    },
    {
      "key": "AL",
      "value": "ALBANIA"
    },
    {
      "key": "DZ",
      "value": "ALGERIA"
    },
    {
      "key": "AS",
      "value": "AMERICAN SAMOA"
    },
    {
      "key": "AD",
      "value": "ANDORRA"
    },
    {
      "key": "AO",
      "value": "ANGOLA"
    },
    {
      "key": "AI",
      "value": "ANGUILLA"
    },
    {
      "key": "AQ",
      "value": "ANTARCTICA"
    },
    {
      "key": "AG",
      "value": "ANTIGUA AND BARBUDA"
    },
    {
      "key": "AR",
      "value": "ARGENTINA"
    },
    {
      "key": "AM",
      "value": "ARMENIA"
    },
    {
      "key": "AW",
      "value": "ARUBA"
    },
    {
      "key": "AU",
      "value": "AUSTRALIA"
    },
    {
      "key": "AT",
      "value": "AUSTRIA"
    },
    {
      "key": "AZ",
      "value": "AZERBAIJAN"
    },
    {
      "key": "BS",
      "value": "BAHAMAS"
    },
    {
      "key": "BH",
      "value": "BAHRAIN"
    },
    {
      "key": "BD",
      "value": "BANGLADESH"
    },
    {
      "key": "BB",
      "value": "BARBADOS"
    },
    {
      "key": "BY",
      "value": "BELARUS"
    },
    {
      "key": "BE",
      "value": "BELGIUM"
    },
    {
      "key": "BZ",
      "value": "BELIZE"
    },
    {
      "key": "BJ",
      "value": "BENIN"
    },
    {
      "key": "BM",
      "value": "BERMUDA"
    },
    {
      "key": "BT",
      "value": "BHUTAN"
    },
    {
      "key": "BO",
      "value": "BOLIVIA"
    },
    {
      "key": "BA",
      "value": "BOSNIA AND HERZEGOVINA"
    },
    {
      "key": "BW",
      "value": "BOTSWANA"
    },
    {
      "key": "BR",
      "value": "BRAZIL"
    },
    {
      "key": "IO",
      "value": "BRITISH INDIAN OCEAN TERRITORY"
    },
    {
      "key": "VG",
      "value": "BRITISH VIRGIN ISLANDS"
    },
    {
      "key": "BN",
      "value": "BRUNEI"
    },
    {
      "key": "BG",
      "value": "BULGARIA"
    },
    {
      "key": "BF",
      "value": "BURKINA FASO"
    },
    {
      "key": "BI",
      "value": "BURUNDI"
    },
    {
      "key": "KH",
      "value": "CAMBODIA"
    },
    {
      "key": "CM",
      "value": "CAMEROON"
    },
    {
      "key": "CA",
      "value": "CANADA"
    },
    {
      "key": "CV",
      "value": "CAPE VERDE"
    },
    {
      "key": "KY",
      "value": "CAYMAN ISLANDS"
    },
    {
      "key": "CF",
      "value": "CENTRAL AFRICAN REPUBLIC"
    },
    {
      "key": "TD",
      "value": "CHAD"
    },
    {
      "key": "CL",
      "value": "CHILE"
    },
    {
      "key": "CN",
      "value": "CHINA"
    },
    {
      "key": "CX",
      "value": "CHRISTMAS ISLAND"
    },
    {
      "key": "CC",
      "value": "COCOS ISLANDS"
    },
    {
      "key": "CO",
      "value": "COLOMBIA"
    },
    {
      "key": "KM",
      "value": "COMOROS"
    },
    {
      "key": "CK",
      "value": "COOK ISLANDS"
    },
    {
      "key": "CR",
      "value": "COSTA RICA"
    },
    {
      "key": "HR",
      "value": "CROATIA"
    },
    {
      "key": "CU",
      "value": "CUBA"
    },
    {
      "key": "CW",
      "value": "CURACAO"
    },
    {
      "key": "CY",
      "value": "CYPRUS"
    },
    {
      "key": "CZ",
      "value": "CZECH REPUBLIC"
    },
    {
      "key": "CD",
      "value": "DEMOCRATIC REPUBLIC OF THE CONGO"
    },
    {
      "key": "DK",
      "value": "DENMARK"
    },
    {
      "key": "DJ",
      "value": "DJIBOUTI"
    },
    {
      "key": "DM",
      "value": "DOMINICA"
    },
    {
      "key": "DO",
      "value": "DOMINICAN REPUBLIC"
    },
    {
      "key": "TL",
      "value": "EAST TIMOR"
    },
    {
      "key": "EC",
      "value": "ECUADOR"
    },
    {
      "key": "EG",
      "value": "EGYPT"
    },
    {
      "key": "SV",
      "value": "EL SALVADOR"
    },
    {
      "key": "GQ",
      "value": "EQUATORIAL GUINEA"
    },
    {
      "key": "ER",
      "value": "ERITREA"
    },
    {
      "key": "EE",
      "value": "ESTONIA"
    },
    {
      "key": "ET",
      "value": "ETHIOPIA"
    },
    {
      "key": "FK",
      "value": "FALKLAND ISLANDS"
    },
    {
      "key": "FO",
      "value": "FAROE ISLANDS"
    },
    {
      "key": "FJ",
      "value": "FIJI"
    },
    {
      "key": "FI",
      "value": "FINLAND"
    },
    {
      "key": "FR",
      "value": "FRANCE"
    },
    {
      "key": "PF",
      "value": "FRENCH POLYNESIA"
    },
    {
      "key": "GA",
      "value": "GABON"
    },
    {
      "key": "GM",
      "value": "GAMBIA"
    },
    {
      "key": "GE",
      "value": "GEORGIA"
    },
    {
      "key": "DE",
      "value": "GERMANY"
    },
    {
      "key": "GH",
      "value": "GHANA"
    },
    {
      "key": "GI",
      "value": "GIBRALTAR"
    },
    {
      "key": "GR",
      "value": "GREECE"
    },
    {
      "key": "GL",
      "value": "GREENLAND"
    },
    {
      "key": "GD",
      "value": "GRENADA"
    },
    {
      "key": "GU",
      "value": "GUAM"
    },
    {
      "key": "GT",
      "value": "GUATEMALA"
    },
    {
      "key": "GG",
      "value": "GUERNSEY"
    },
    {
      "key": "GN",
      "value": "GUINEA"
    },
    {
      "key": "GW",
      "value": "GUINEA-BISSAU"
    },
    {
      "key": "GY",
      "value": "GUYANA"
    },
    {
      "key": "HT",
      "value": "HAITI"
    },
    {
      "key": "HN",
      "value": "HONDURAS"
    },
    {
      "key": "HK",
      "value": "HONG KONG"
    },
    {
      "key": "HU",
      "value": "HUNGARY"
    },
    {
      "key": "IS",
      "value": "ICELAND"
    },
    {
      "key": "IN",
      "value": "INDIA"
    },
    {
      "key": "ID",
      "value": "INDONESIA"
    },
    {
      "key": "IR",
      "value": "IRAN"
    },
    {
      "key": "IQ",
      "value": "IRAQ"
    },
    {
      "key": "IE",
      "value": "IRELAND"
    },
    {
      "key": "IM",
      "value": "ISLE OF MAN"
    },
    {
      "key": "IL",
      "value": "ISRAEL"
    },
    {
      "key": "IT",
      "value": "ITALY"
    },
    {
      "key": "CI",
      "value": "IVORY COAST"
    },
    {
      "key": "JM",
      "value": "JAMAICA"
    },
    {
      "key": "JP",
      "value": "JAPAN"
    },
    {
      "key": "JE",
      "value": "JERSEY"
    },
    {
      "key": "JO",
      "value": "JORDAN"
    },
    {
      "key": "KZ",
      "value": "KAZAKHSTAN"
    },
    {
      "key": "KE",
      "value": "KENYA"
    },
    {
      "key": "KI",
      "value": "KIRIBATI"
    },
    {
      "key": "XK",
      "value": "KOSOVO"
    },
    {
      "key": "KW",
      "value": "KUWAIT"
    },
    {
      "key": "KG",
      "value": "KYRGYZSTAN"
    },
    {
      "key": "LA",
      "value": "LAOS"
    },
    {
      "key": "LV",
      "value": "LATVIA"
    },
    {
      "key": "LB",
      "value": "LEBANON"
    },
    {
      "key": "LS",
      "value": "LESOTHO"
    },
    {
      "key": "LR",
      "value": "LIBERIA"
    },
    {
      "key": "LY",
      "value": "LIBYA"
    },
    {
      "key": "LI",
      "value": "LIECHTENSTEIN"
    },
    {
      "key": "LT",
      "value": "LITHUANIA"
    },
    {
      "key": "LU",
      "value": "LUXEMBOURG"
    },
    {
      "key": "MO",
      "value": "MACAU"
    },
    {
      "key": "MK",
      "value": "MACEDONIA"
    },
    {
      "key": "MG",
      "value": "MADAGASCAR"
    },
    {
      "key": "MW",
      "value": "MALAWI"
    },
    {
      "key": "MY",
      "value": "MALAYSIA"
    },
    {
      "key": "MV",
      "value": "MALDIVES"
    },
    {
      "key": "ML",
      "value": "MALI"
    },
    {
      "key": "MT",
      "value": "MALTA"
    },
    {
      "key": "MH",
      "value": "MARSHALL ISLANDS"
    },
    {
      "key": "MR",
      "value": "MAURITANIA"
    },
    {
      "key": "MU",
      "value": "MAURITIUS"
    },
    {
      "key": "YT",
      "value": "MAYOTTE"
    },
    {
      "key": "MX",
      "value": "MEXICO"
    },
    {
      "key": "FM",
      "value": "MICRONESIA"
    },
    {
      "key": "MD",
      "value": "MOLDOVA"
    },
    {
      "key": "MC",
      "value": "MONACO"
    },
    {
      "key": "MN",
      "value": "MONGOLIA"
    },
    {
      "key": "ME",
      "value": "MONTENEGRO"
    },
    {
      "key": "MS",
      "value": "MONTSERRAT"
    },
    {
      "key": "MA",
      "value": "MOROCCO"
    },
    {
      "key": "MZ",
      "value": "MOZAMBIQUE"
    },
    {
      "key": "MM",
      "value": "MYANMAR"
    },
    {
      "key": "NA",
      "value": "NAMIBIA"
    },
    {
      "key": "NR",
      "value": "NAURU"
    },
    {
      "key": "NP",
      "value": "NEPAL"
    },
    {
      "key": "NL",
      "value": "NETHERLANDS"
    },
    {
      "key": "AN",
      "value": "NETHERLANDS ANTILLES"
    },
    {
      "key": "NC",
      "value": "NEW CALEDONIA"
    },
    {
      "key": "NZ",
      "value": "NEW ZEALAND"
    },
    {
      "key": "NI",
      "value": "NICARAGUA"
    },
    {
      "key": "NE",
      "value": "NIGER"
    },
    {
      "key": "NG",
      "value": "NIGERIA"
    },
    {
      "key": "NU",
      "value": "NIUE"
    },
    {
      "key": "KP",
      "value": "NORTH KOREA"
    },
    {
      "key": "MP",
      "value": "NORTHERN MARIANA ISLANDS"
    },
    {
      "key": "NO",
      "value": "NORWAY"
    },
    {
      "key": "OM",
      "value": "OMAN"
    },
    {
      "key": "PK",
      "value": "PAKISTAN"
    },
    {
      "key": "PW",
      "value": "PALAU"
    },
    {
      "key": "PS",
      "value": "PALESTINE"
    },
    {
      "key": "PA",
      "value": "PANAMA"
    },
    {
      "key": "PG",
      "value": "PAPUA NEW GUINEA"
    },
    {
      "key": "PY",
      "value": "PARAGUAY"
    },
    {
      "key": "PE",
      "value": "PERU"
    },
    {
      "key": "PH",
      "value": "PHILIPPINES"
    },
    {
      "key": "PN",
      "value": "PITCAIRN"
    },
    {
      "key": "PL",
      "value": "POLAND"
    },
    {
      "key": "PT",
      "value": "PORTUGAL"
    },
    {
      "key": "PR",
      "value": "PUERTO RICO"
    },
    {
      "key": "QA",
      "value": "QATAR"
    },
    {
      "key": "CG",
      "value": "REPUBLIC OF THE CONGO"
    },
    {
      "key": "RE",
      "value": "REUNION"
    },
    {
      "key": "RO",
      "value": "ROMANIA"
    },
    {
      "key": "RU",
      "value": "RUSSIA"
    },
    {
      "key": "RW",
      "value": "RWANDA"
    },
    {
      "key": "BL",
      "value": "SAINT BARTHELEMY"
    },
    {
      "key": "SH",
      "value": "SAINT HELENA"
    },
    {
      "key": "KN",
      "value": "SAINT KITTS AND NEVIS"
    },
    {
      "key": "LC",
      "value": "SAINT LUCIA"
    },
    {
      "key": "MF",
      "value": "SAINT MARTIN"
    },
    {
      "key": "PM",
      "value": "SAINT PIERRE AND MIQUELON"
    },
    {
      "key": "VC",
      "value": "SAINT VINCENT AND THE GRENADINES"
    },
    {
      "key": "WS",
      "value": "SAMOA"
    },
    {
      "key": "SM",
      "value": "SAN MARINO"
    },
    {
      "key": "ST",
      "value": "SAO TOME AND PRINCIPE"
    },
    {
      "key": "SA",
      "value": "SAUDI ARABIA"
    },
    {
      "key": "SN",
      "value": "SENEGAL"
    },
    {
      "key": "RS",
      "value": "SERBIA"
    },
    {
      "key": "SC",
      "value": "SEYCHELLES"
    },
    {
      "key": "SL",
      "value": "SIERRA LEONE"
    },
    {
      "key": "SG",
      "value": "SINGAPORE"
    },
    {
      "key": "SX",
      "value": "SINT MAARTEN"
    },
    {
      "key": "SK",
      "value": "SLOVAKIA"
    },
    {
      "key": "SI",
      "value": "SLOVENIA"
    },
    {
      "key": "SB",
      "value": "SOLOMON ISLANDS"
    },
    {
      "key": "SO",
      "value": "SOMALIA"
    },
    {
      "key": "ZA",
      "value": "SOUTH AFRICA"
    },
    {
      "key": "KR",
      "value": "SOUTH KOREA"
    },
    {
      "key": "SS",
      "value": "SOUTH SUDAN"
    },
    {
      "key": "ES",
      "value": "SPAIN"
    },
    {
      "key": "LK",
      "value": "SRI LANKA"
    },
    {
      "key": "SD",
      "value": "SUDAN"
    },
    {
      "key": "SR",
      "value": "SURINAME"
    },
    {
      "key": "SJ",
      "value": "SVALBARD AND JAN MAYEN"
    },
    {
      "key": "SZ",
      "value": "SWAZILAND"
    },
    {
      "key": "SE",
      "value": "SWEDEN"
    },
    {
      "key": "CH",
      "value": "SWITZERLAND"
    },
    {
      "key": "SY",
      "value": "SYRIA"
    },
    {
      "key": "TW",
      "value": "TAIWAN"
    },
    {
      "key": "TJ",
      "value": "TAJIKISTAN"
    },
    {
      "key": "TZ",
      "value": "TANZANIA"
    },
    {
      "key": "TH",
      "value": "THAILAND"
    },
    {
      "key": "TG",
      "value": "TOGO"
    },
    {
      "key": "TK",
      "value": "TOKELAU"
    },
    {
      "key": "TO",
      "value": "TONGA"
    },
    {
      "key": "TT",
      "value": "TRINIDAD AND TOBAGO"
    },
    {
      "key": "TN",
      "value": "TUNISIA"
    },
    {
      "key": "TR",
      "value": "TURKEY"
    },
    {
      "key": "TM",
      "value": "TURKMENISTAN"
    },
    {
      "key": "TC",
      "value": "TURKS AND CAICOS ISLANDS"
    },
    {
      "key": "TV",
      "value": "TUVALU"
    },
    {
      "key": "VI",
      "value": "U.S. VIRGIN ISLANDS"
    },
    {
      "key": "UG",
      "value": "UGANDA"
    },
    {
      "key": "UA",
      "value": "UKRAINE"
    },
    {
      "key": "AE",
      "value": "UNITED ARAB EMIRATES"
    },
    {
      "key": "GB",
      "value": "UNITED KINGDOM"
    },
    {
      "key": "US",
      "value": "UNITED STATES"
    },
    {
      "key": "UY",
      "value": "URUGUAY"
    },
    {
      "key": "UZ",
      "value": "UZBEKISTAN"
    },
    {
      "key": "VU",
      "value": "VANUATU"
    },
    {
      "key": "VA",
      "value": "VATICAN"
    },
    {
      "key": "VE",
      "value": "VENEZUELA"
    },
    {
      "key": "VN",
      "value": "VIETNAM"
    },
    {
      "key": "WF",
      "value": "WALLIS AND FUTUNA"
    },
    {
      "key": "EH",
      "value": "WESTERN SAHARA"
    },
    {
      "key": "YE",
      "value": "YEMEN"
    },
    {
      "key": "ZM",
      "value": "ZAMBIA"
    },
    {
      "key": "ZW",
      "value": "ZIMBABWE"
    }
  ]
}

