import React from 'react';
import classes from './Layout.module.scss';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import variables from '../../variables';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlotingBanner from '../Banner/FlotingBanner';

const Layout = ( props ) => {
  const showBackdrop = props.showBackdrop ? props.showBackdrop : false
  let layoutStyle = null;
  if (!(props.hideFooter === undefined || props.hideFooter === false)) {
    layoutStyle = {
      gridTemplateRows: `${variables.headerHeight} calc(100vh - ${variables.headerHeight})`
    }
  }

  return (
    <div className={classes.Container} style={layoutStyle}>
      <div className={classes.Header}>
        <Navbar centerBrand={props.centerBrand} />
      </div>
      <main className={classes.Content}>
        { props.children }
      </main>
      { props.hideFooter === undefined || props.hideFooter === false ?
      <div className={classes.Footer}>
        <Footer />
      </div> : null }
      <Backdrop style={{zIndex: '100', color: '#fff'}} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      { 
        props.hideBanner ? null :
        <FlotingBanner url='/register' />
      }
    </div>
  );
}

export default Layout;
