exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.FlotingBanner_FlotingBanner__2d7BF {\n  position: absolute;\n  bottom: 50px;\n  width: 480px;\n  height: 90px;\n  background-color: #333333;\n  z-index: 9;\n  border-radius: 20px;\n  display: grid;\n  grid-template-columns: auto 80px;\n  color: #FFF;\n  cursor: pointer; }\n  @media only screen and (max-width: 767px) {\n    .FlotingBanner_FlotingBanner__2d7BF {\n      width: 100%;\n      height: 80px;\n      grid-template-columns: auto 60px; } }\n  .FlotingBanner_FlotingBanner__2d7BF .FlotingBanner_Info__4qlnO {\n    display: grid;\n    justify-items: left;\n    padding: 10px 20px; }\n  .FlotingBanner_FlotingBanner__2d7BF .FlotingBanner_Title__23oqf {\n    font-size: 20px;\n    font-weight: 700;\n    align-self: end; }\n    @media only screen and (max-width: 767px) {\n      .FlotingBanner_FlotingBanner__2d7BF .FlotingBanner_Title__23oqf {\n        font-size: 16px; } }\n  @media only screen and (max-width: 767px) {\n    .FlotingBanner_FlotingBanner__2d7BF .FlotingBanner_Subtitle__3Lk1F {\n      font-size: 12px; } }\n  .FlotingBanner_FlotingBanner__2d7BF .FlotingBanner_Icon__2DExA {\n    display: grid;\n    justify-items: center;\n    align-items: center; }\n", ""]);

// Exports
exports.locals = {
	"FlotingBanner": "FlotingBanner_FlotingBanner__2d7BF",
	"Info": "FlotingBanner_Info__4qlnO",
	"Title": "FlotingBanner_Title__23oqf",
	"Subtitle": "FlotingBanner_Subtitle__3Lk1F",
	"Icon": "FlotingBanner_Icon__2DExA"
};