exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Layout_Container__CrZiY {\n  position: relative;\n  display: grid;\n  grid-template-columns: 100vw;\n  grid-template-rows: 63px auto 40px;\n  justify-items: center;\n  min-height: 100vh;\n  background-color: #EFEFEF; }\n\n.Layout_Header__25KqD {\n  grid-row: 1;\n  width: calc(100% - 120px);\n  padding: 5px 60px;\n  display: grid;\n  align-items: center;\n  border-bottom: 1px solid #FFFFFF;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);\n  position: fixed;\n  top: 0;\n  z-index: 99;\n  background-color: #FFF;\n  color: #FFFFFF;\n  border: 0; }\n\n.Layout_Content__2ZjSY {\n  align-self: center;\n  grid-row: 2;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-auto-rows: minmax(min-content, max-content);\n  width: calc(100% - 0px);\n  max-width: 100%;\n  padding: 0px 0px;\n  padding-bottom: 0 !important;\n  z-index: 1;\n  background-color: #EFEFEF;\n  height: 100%; }\n\n.Layout_Footer__1NUYI {\n  grid-row: 3;\n  display: grid;\n  width: calc(100% - 0px);\n  padding: 5px 0px;\n  background-color: #1A3E70; }\n\n/* Mobile */\n@media only screen and (max-width: 767px) {\n  .Layout_Header__25KqD {\n    width: calc(100% - 20px);\n    padding: 5px 10px; }\n  .Layout_Content__2ZjSY {\n    width: 100%; }\n  .Layout_Footer__1NUYI {\n    width: calc(100% - 20px);\n    padding: 5px 10px; } }\n", ""]);

// Exports
exports.locals = {
	"Container": "Layout_Container__CrZiY",
	"Header": "Layout_Header__25KqD",
	"Content": "Layout_Content__2ZjSY",
	"Footer": "Layout_Footer__1NUYI"
};