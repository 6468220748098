import React from 'react';
import classes from './Dashboard.module.scss';
import Layout from '../../components/Layout/Layout';
import SearchBox from '../../components/Input/SearchInput';
import StartupServices from '../../services/startups.services';
import { getPage } from '../../utils/filters';
import { FormattedMessage, injectIntl } from 'react-intl';
import { filterAll } from '../../utils/filters';

const Dashboard = props => {
  const { intl } = props;
  const [startups, setStartups] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const resultSearchBoxRef = React.useRef(null)
  const [keyword, setKeyword] = React.useState(null)

  React.useEffect(() => {
    StartupServices.data('all=true')
    .then(result => {
      setStartups(result);
      setData(result);
    })
    .catch(err => {
      console.log(err)
    })
  }, []);

  const onSearch = () => {
    const filters = {location: resultSearchBoxRef.current.value}
    const result = filterAll(startups, filters);
    setData(result);
  }

  return (
    <Layout showBackdrop={false} hideBanner={true}>
      <div className={classes.Dashboard}>
        <div className={classes.Title}>
          <FormattedMessage id="app.dashboard.title" defaultMessage="" />
        </div>
        <div className={classes.Options}>
          <SearchBox forwardedRef={resultSearchBoxRef} onSearch={onSearch} defaultValue={keyword} placeholder={intl.formatMessage({id: 'app.dashboard.search.hover', defaultMessage: ''})} />
        </div>
        <div className={classes.Body}>
          <div className={classes.Header}>
            <div><FormattedMessage id="app.dashboard.country" defaultMessage="" /></div>
            <div><FormattedMessage id="app.dashboard.name" defaultMessage="" /></div>
            <div><FormattedMessage id="app.dashboard.website" defaultMessage="" /></div>
            <div><FormattedMessage id="app.dashboard.category" defaultMessage="" /></div>
            <div><FormattedMessage id="app.dashboard.type" defaultMessage="" /></div>
            <div></div>
          </div>
          {
            getPage(data, page).map((item, index) => {
              let rowStyle = {}
              if (item.status !== 'ACTIVE') {
                rowStyle = {borderLeft: '10px solid #003870'}
              }
              return (
                <div key={index} style={rowStyle} className={classes.Row}>
                  <div>{item.country}</div>
                  <div>{item.name}</div>
                  <div>{item.website}</div>
                  <div>{intl.formatMessage({id: `app.map.filters.options.${item.category}`, defaultMessage: item.category})}</div>
                  <div>{item.type}</div>
                  <div>
                    <a href={`/company?id=${item.id}`}>
                      <FormattedMessage id="app.dashboard.details" defaultMessage="" />
                    </a>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={classes.Footer}>
          <div style={{justifySelf: 'left', cursor: 'pointer'}} onClick={() => { if ((page-1) < 0) {return} setPage(page - 1) } }>
            <FormattedMessage id="app.dashboard.previous" defaultMessage="" />
          </div>
          <div style={{justifySelf: 'right', cursor: 'pointer'}} onClick={() => setPage(page + 1)}>
            <FormattedMessage id="app.dashboard.next" defaultMessage="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(Dashboard);