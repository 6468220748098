export const getFilters = (data, field) => {
  let fields = field
  if (!Array.isArray(field)) {
    fields = [field]
  }

  const filters = {}
  
  for (let index = 0; index < fields.length; index++) {
    const fld = fields[index]
    const reducer = (accumulator, currentValue) => {
      const dataValue = currentValue[fld]
      if (typeof dataValue === 'boolean' || dataValue) {
        if (Array.isArray(dataValue)) {
          for(var i = 0; i < dataValue.length; i++) {
            if (!accumulator.includes(dataValue[i])) {
              accumulator.push(dataValue[i])
            }
          }
        } else
        if (typeof dataValue === 'boolean' ) {
          if (!accumulator.includes('Si')) {
            accumulator.push('Si')
          }
          if (!accumulator.includes('No')) {
            accumulator.push('No')
          }
        } else
        if (!accumulator.includes(dataValue)) {
          accumulator.push(dataValue)
        }
      }
      return accumulator
    };
    filters[fld] = data.reduce(reducer, [])
  }
  return filters
}

export const filter = (data, field, value) => {
  let fields = field
  if (!Array.isArray(field)) {
    fields = [field]
  }
  let keywords = Array.isArray(value) ? value : [value]
  // if (fields.includes('region') || fields.includes('country')) {
  //   keywords = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  // }
  
  const result = data.filter(item => {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (item[element] == null) {
        continue
      }
      if (Array.isArray(item[element])) {
        for (let i = 0; i < keywords.length; i++) {
          if (item[element].includes(keywords[i])) {
            return true
          }
        }
        continue
      }
      const fieldValue = typeof item[element] === 'boolean' ? item[element] : item[element].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") 
      for (let i = 0; i < keywords.length; i++) {
        let keyword = typeof keywords[i] === 'boolean' ? keywords[i] : keywords[i].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        if (typeof fieldValue === 'boolean' && fieldValue === keyword) {
          return true
        }
        if (typeof fieldValue != 'boolean' && fieldValue.indexOf(keyword) !== -1) {
          return true
        }
      }
      continue
    }
    return false
  })

  return result
}

export const filterAll = (data, filters) => {
  let filteredData = data
  Object.keys(filters).forEach((key) => {
    if (key === 'location') {
      filteredData = filter(filteredData, ['region', 'country', 'name'], filters[key])
    } else {
      filteredData = filter(filteredData, key, filters[key])
    }
  });
  return filteredData;
}

export const getPage = (data, page) => {
  return data.slice(page*10, (page+1)*10);
}