exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Carousel_CarouselContent__1m4wH {\n  display: flex;\n  flex-direction: row; }\n  .Carousel_CarouselContent__1m4wH .Carousel_CarouselItem__1-0Av {\n    width: calc(100% - 0px);\n    display: block;\n    overflow: hidden; }\n\n.Carousel_Pagination__u1uVf {\n  display: flex;\n  flex-direction: row;\n  padding: 10px 0;\n  justify-content: flex-start;\n  cursor: pointer; }\n  .Carousel_Pagination__u1uVf .Carousel_PaginationBullet__krvLx {\n    width: 12px;\n    height: 12px;\n    display: inline-block;\n    border-radius: 100%;\n    background: #1A3E70;\n    opacity: 0.3;\n    margin: 0 2px; }\n  .Carousel_Pagination__u1uVf .Carousel_PaginationBulletActive__3m3mF {\n    opacity: 1;\n    width: 30px;\n    background: #1A3E70;\n    border-radius: 12px;\n    transition: all 0.3s ease;\n    cursor: auto; }\n", ""]);

// Exports
exports.locals = {
	"CarouselContent": "Carousel_CarouselContent__1m4wH",
	"CarouselItem": "Carousel_CarouselItem__1-0Av",
	"Pagination": "Carousel_Pagination__u1uVf",
	"PaginationBullet": "Carousel_PaginationBullet__krvLx",
	"PaginationBulletActive": "Carousel_PaginationBulletActive__3m3mF"
};