import * as Yup from 'yup';

const Login = Yup.object().shape({
  username: Yup.string()
    .email('Correo electrónico inválido')
    .min(6, 'Correo electrónico inválido')
    .required('Campo obligatorio'),
  password: Yup.string()
    .min(6, 'Contraseña es mayor de 6 caracteres')
    .required('Campo obligatorio')
})

export default Login;