import React from 'react';
import classes from './Banner.module.scss';
import PrimaryButton from '../Button/PrimaryButton'
import { isMobile } from 'react-device-detect';

const Banner = (props) => {
  return (
    <div className={classes.Banner}>
      <div className={classes.Text}>
        <div>
          <div className={classes.Title}>
            { props.title }
          </div>
          <div className={classes.Description}>
            { props.description }
          </div>
          <div className={classes.Button}>
            <PrimaryButton style={isMobile ? {fontSize: '12px'} : {}} onClick={props.onClick}>
              { props.buttonLabel}
            </PrimaryButton>
            &nbsp;
            &nbsp;
            &nbsp;
            <PrimaryButton style={isMobile ? {fontSize: '12px'} : {}} onClick={props.onClick2}>
              { props.button2Label}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={classes.Image}>
        <img src={props.imageUrl} alt='banner' />
      </div>
    </div>
  )
}

export default Banner;