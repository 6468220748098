import Select from '@material-ui/core/Select';
import styled from 'styled-components'

const SelectInput = styled(Select)`
  &&.MuiInput-underline:before {
    border-bottom: 0;
  }

  &&.MuiInput-underline:hover {
    border-bottom: 0;
  }
`

export default SelectInput;