import styled from 'styled-components'
import Radio from '@material-ui/core/Radio';

const RadioButton = styled(Radio)`
  && {
    color: #FF7D32;
  }

  &&.MuiRadio-colorSecondary.Mui-checked {
    color: #FF7D32;
  }
`

export default RadioButton;
