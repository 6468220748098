import React from 'react';
import classes from './Home.module.scss';
import Layout from '../../components/Layout/Layout';
import Carousel from '../../components/Carousel/Carousel';
import Banner from '../../components/Banner/Banner';
import PrimaryButton from '../../components/Button/PrimaryButton';
import StatCard from '../../components/Cards/StatCard';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import StartupServices from '../../services/startups.services';

const Home = (props) => {
  const { intl } = props;
  const [stats, setStats] = React.useState({})

  React.useEffect(() => {
    StartupServices.stats()
    .then(result => {
      setStats(result)
    })
    .catch(err => {
      console.log(err)
    })
  }, []);

  return (
    <Layout showBackdrop={false}>
      <div className={classes.CarouselContainer}>
        <Carousel id='1' interval={8000} noOfCards={1} noOfItems={4} showChevron={false} showPagination={true}>
          <div className={classes.MainBanner}>
            <div className={classes.Title}>
              <div style={isMobile ? {textAlign: 'center'} : {display: 'flex'}}>
                <div>
                  <FormattedMessage id="app.home.welcome" defaultMessage="" />&nbsp;
                </div>
                <div style={{fontWeight: 'bold'}}>Geoportal</div>
              </div>
            </div>
            <div className={classes.Description}>
              <div>
                <FormattedMessage id="app.home.welcome.description" defaultMessage="" />
              </div>
            </div>
            <div className={classes.Button}>
              <PrimaryButton style={isMobile ? {fontSize: '12px'} : {}} onClick={() => { window.location.href = '/map?mode=LIST'; }}>
                <FormattedMessage id="app.home.welcome.button" defaultMessage="" />
              </PrimaryButton>
            </div>
            <div className={classes.Stats}>
              <StatCard imageUrl='/images/agritechs.svg' value={stats.agritech} label={intl.formatMessage({id: 'app.home.welcome.agritechs', defaultMessage: ''})} />
              <StatCard imageUrl='/images/fintechs.svg' value={stats.fintech} label={intl.formatMessage({id: 'app.home.welcome.fintechs', defaultMessage: ''})} />
              <StatCard imageUrl='/images/countries.svg' value={stats.number_countries} label={intl.formatMessage({id: 'app.home.welcome.countries', defaultMessage: ''})} />
            </div>
          </div>
          <Banner 
            title={
            <div style={isMobile ? {} : {display: 'flex'}}>
              <div><FormattedMessage id="app.home.map.title.1" defaultMessage="" />&nbsp;</div>
              <div style={{fontWeight: 'bold'}}><FormattedMessage id="app.home.map.title.2" defaultMessage="" /></div>
            </div>
            } 
            description={intl.formatMessage({id: 'app.home.map.description', defaultMessage: ''})}
            buttonLabel={intl.formatMessage({id: 'app.home.map.button.list', defaultMessage: ''})}
            button2Label={intl.formatMessage({id: 'app.home.map.button.map', defaultMessage: ''})}
            imageUrl='/images/map.png'
            onClick={() => { window.location.href = '/map?mode=LIST'; }}
            onClick2={() => { window.location.href = '/map'; }} />
          <Banner 
            title={
            <div style={isMobile ? {} : {display: 'flex'}}>
              <div><FormattedMessage id="app.home.agritech.title.1" defaultMessage="" />&nbsp;</div>
              <div style={{fontWeight: 'bold'}}><FormattedMessage id="app.home.agritech.title.2" defaultMessage="" /></div>
            </div>
            } 
            description={intl.formatMessage({id: 'app.home.agritech.description', defaultMessage: ''})}
            buttonLabel={intl.formatMessage({id: 'app.home.agritech.button.list', defaultMessage: ''})}
            button2Label={intl.formatMessage({id: 'app.home.agritech.button.map', defaultMessage: ''})}
            imageUrl='/images/agritech.png'
            onClick={() => { window.location.href = '/map?mode=LIST&type=AGRITECH'; }}
            onClick2={() => { window.location.href = '/map?type=AGRITECH'; }} />
          <Banner 
            title={
            <div style={isMobile ? {} : {display: 'flex'}}>
              <div><FormattedMessage id="app.home.fintech.title.1" defaultMessage="" />&nbsp;</div>
              <div style={{fontWeight: 'bold'}}><FormattedMessage id="app.home.fintech.title.2" defaultMessage="" /></div>
            </div>
            } 
            description={intl.formatMessage({id: 'app.home.fintech.description', defaultMessage: ''})}
            buttonLabel={intl.formatMessage({id: 'app.home.fintech.button.list', defaultMessage: ''})}
            button2Label={intl.formatMessage({id: 'app.home.fintech.button.map', defaultMessage: ''})}
            imageUrl='/images/fintech.png'
            onClick={() => { window.location.href = '/map?mode=LIST&type=FINTECH'; }}
            onClick2={() => { window.location.href = '/map?type=FINTECH'; }} />
        </Carousel>
        { 
          isMobile ? <div style={{height: '100px'}}></div> : null
        }
      </div>
    </Layout>
  )
}

export default injectIntl(Home);