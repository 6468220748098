exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.About_About__2qdyV {\n  display: grid;\n  justify-items: center; }\n  .About_About__2qdyV .About_Image__3bCig {\n    width: 887px;\n    text-align: center; }\n    @media only screen and (max-width: 767px) {\n      .About_About__2qdyV .About_Image__3bCig {\n        width: 100%; }\n        .About_About__2qdyV .About_Image__3bCig img {\n          width: 100%; } }\n  .About_About__2qdyV .About_Text__37HNm {\n    width: 887px;\n    color: #282828; }\n    @media only screen and (max-width: 767px) {\n      .About_About__2qdyV .About_Text__37HNm {\n        width: 100%; } }\n    .About_About__2qdyV .About_Text__37HNm .About_Title__IObuB {\n      font-size: 24px;\n      font-weight: 700;\n      text-align: center; }\n    .About_About__2qdyV .About_Text__37HNm .About_Content__1JvAZ {\n      padding: 15px 5px;\n      text-align: justify; }\n      @media only screen and (max-width: 767px) {\n        .About_About__2qdyV .About_Text__37HNm .About_Content__1JvAZ {\n          padding: 15px 20px; } }\n    .About_About__2qdyV .About_Text__37HNm .About_Footer__gCymi {\n      padding: 15px 100px;\n      text-align: center; }\n      @media only screen and (max-width: 767px) {\n        .About_About__2qdyV .About_Text__37HNm .About_Footer__gCymi {\n          padding: 15px 20px; } }\n", ""]);

// Exports
exports.locals = {
	"About": "About_About__2qdyV",
	"Image": "About_Image__3bCig",
	"Text": "About_Text__37HNm",
	"Title": "About_Title__IObuB",
	"Content": "About_Content__1JvAZ",
	"Footer": "About_Footer__gCymi"
};