import React, { useContext } from 'react';
import classes from './SecundaryLayout.module.scss';
import Select from '../Input/Select';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Context } from '../Intl/Intl';
import { FormattedMessage } from 'react-intl';

const SecundaryLayout = props => {
  const intlContext = useContext(Context);

  return (
    <div className={classes.Container}>
      <div className={classes.Image}>
        <img src={props.image} alt='image' />
      </div>
      <div className={classes.Main}>
        <div className={classes.Header}>
          <div className={classes.Return} onClick={props.onReturn}>
            <KeyboardBackspaceIcon/><span>
              <FormattedMessage id="app.startup.register.back" defaultMessage="" />
            </span>
          </div>
          <div></div>
          <div className={classes.Lang}>
            <Select
              id='lang'
              value={intlContext.language}
              onChange={intlContext.selectLanguage}
              inputProps={{
                name: 'lang'
              }}
              native
            >
              <option key='1' value='es'>es</option>
              <option key='2' value='en'>en</option>
            </Select>
          </div>
        </div>
        <div className={classes.Body}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default SecundaryLayout;