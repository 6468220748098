exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Banner_Banner__1gOSG {\n  height: 620px;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 60% 40%; }\n  @media only screen and (max-width: 767px) {\n    .Banner_Banner__1gOSG {\n      grid-template-columns: 1fr; } }\n  .Banner_Banner__1gOSG .Banner_Text__2cW83 {\n    display: grid;\n    grid-template-columns: 1fr;\n    align-items: center;\n    width: 580px;\n    padding: 10px 20px; }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Text__2cW83 {\n        width: 100%;\n        box-sizing: border-box; } }\n  .Banner_Banner__1gOSG .Banner_Title__17dVY {\n    font-size: 36px; }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Title__17dVY {\n        font-size: 30px;\n        text-align: center; } }\n  .Banner_Banner__1gOSG .Banner_Description__3d8eg {\n    font-size: 18px;\n    color: #2E384D;\n    padding: 10px 0; }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Description__3d8eg {\n        font-size: 16px;\n        text-align: center; } }\n  .Banner_Banner__1gOSG .Banner_Button__OSOJo {\n    padding: 10px 0; }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Button__OSOJo button {\n        margin: 10px 0;\n        padding: 10px; } }\n  .Banner_Banner__1gOSG .Banner_Image__30XwH {\n    justify-self: end; }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Image__30XwH {\n        justify-self: center; } }\n    @media only screen and (max-width: 767px) {\n      .Banner_Banner__1gOSG .Banner_Image__30XwH img {\n        width: 200px; } }\n", ""]);

// Exports
exports.locals = {
	"Banner": "Banner_Banner__1gOSG",
	"Text": "Banner_Text__2cW83",
	"Title": "Banner_Title__17dVY",
	"Description": "Banner_Description__3d8eg",
	"Button": "Banner_Button__OSOJo",
	"Image": "Banner_Image__30XwH"
};