import React from 'react';
import Layout from '../../components/Layout/Layout';

const Disclaimer = props => {
  return (
    <Layout showBackdrop={false} hideBanner={true}>
      <div>
        
      </div>
    </Layout>
  )
}

export default Disclaimer;