import * as Yup from 'yup';
var luhn = require("luhn");

Yup.addMethod(Yup.number, 'length', function (len, message) {
  return this.test('length', message, function(value) {
    if (value) {
      return value.toString().length === len;
    }
  })
})

Yup.addMethod(Yup.string, 'creditCard', function (message) {
  return this.test('creditCard', message, function(value) {
    if (value) {
      return luhn.validate(value.toString());
    }
  })
})

const Register = Yup.object().shape({
  type: Yup.string()
    .required('Campo obligatorio'),
  name: Yup.string()
    .required('Campo obligatorio'),
  document: Yup.string()
    .required('Campo obligatorio'),
  website: Yup.string()
    .required('Campo obligatorio'),
  manager: Yup.string()
    .required('Campo obligatorio'),
  managerEmail: Yup.string()
    .email('Correo electrónico inválido')
    .min(6, 'Correo electrónico inválido')
    .required('Campo obligatorio'),
  country: Yup.string()
    .required('Campo obligatorio'),
  mainCategory: Yup.string()
    .required('Campo obligatorio'),
  logo: Yup.string()
    .required('Campo obligatorio'),
  description: Yup.string()
    .required('Campo obligatorio'),
  women: Yup.string()
    .required('Campo obligatorio'),
  womenNumber: Yup.string()
    .required('Campo obligatorio'),
  young: Yup.string()
    .required('Campo obligatorio'),
  youngNumber: Yup.string()
    .required('Campo obligatorio'),
  rural: Yup.string()
    .required('Campo obligatorio'),
  ruralNumber: Yup.string()
    .required('Campo obligatorio')
})

export default Register;