import React, { useState } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import messages_es from "../../i18n/es.json";
import messages_en from "../../i18n/en.json";

export const Context = React.createContext();

const all_messages = {
  'es': messages_es,
  'en': messages_en
};

const locale = localStorage.getItem('lang')
              || (navigator.languages && navigator.languages[0]) 
              || navigator.language 
              || navigator.userLanguage;

const lang = locale.split(/[-_]/)[0];
localStorage.setItem('lang', lang)

addLocaleData([...locale_en, ...locale_es]);

const Intl = (props) => {
  const [language, setLanguage] = useState(lang);
  const [messages, setMessages] = useState(all_messages[language] ?? all_messages['es']);

  function selectLanguage(e) {
    const newLang = e.target.value;
    setLanguage(newLang);
    localStorage.setItem('lang', newLang)

    if (newLang === 'en') {
      setMessages(all_messages[lang])
    } else {
      setMessages(all_messages['es'])
    }
  }
  
  return (
    <Context.Provider value={{language, selectLanguage}}>
      <IntlProvider messages={messages} locale={language}>
        { props.children }
      </IntlProvider>
    </Context.Provider>
  )
}

export default Intl;