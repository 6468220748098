exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.StatCard_StatCard__UjO2C {\n  height: 260px;\n  width: 200px;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 160px auto; }\n  @media only screen and (max-width: 767px) {\n    .StatCard_StatCard__UjO2C {\n      width: calc(calc(100vw - 30px) / 3);\n      height: 80px;\n      grid-template-rows: 80px auto; } }\n  .StatCard_StatCard__UjO2C .StatCard_Image__20zld {\n    justify-self: center; }\n    .StatCard_StatCard__UjO2C .StatCard_Image__20zld img {\n      width: 140px; }\n      @media only screen and (max-width: 767px) {\n        .StatCard_StatCard__UjO2C .StatCard_Image__20zld img {\n          width: 70px; } }\n  .StatCard_StatCard__UjO2C .StatCard_Stat__1-ndi {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: 60% 40%; }\n    .StatCard_StatCard__UjO2C .StatCard_Stat__1-ndi .StatCard_Value__2KWCk {\n      font-size: 34px;\n      font-weight: bold;\n      align-self: flex-end;\n      justify-self: center; }\n      @media only screen and (max-width: 767px) {\n        .StatCard_StatCard__UjO2C .StatCard_Stat__1-ndi .StatCard_Value__2KWCk {\n          font-size: 24px; } }\n    .StatCard_StatCard__UjO2C .StatCard_Stat__1-ndi .StatCard_Label__NxeZ2 {\n      font-size: 20px;\n      align-self: flex-start;\n      justify-self: center; }\n      @media only screen and (max-width: 767px) {\n        .StatCard_StatCard__UjO2C .StatCard_Stat__1-ndi .StatCard_Label__NxeZ2 {\n          font-size: 16px; } }\n", ""]);

// Exports
exports.locals = {
	"StatCard": "StatCard_StatCard__UjO2C",
	"Image": "StatCard_Image__20zld",
	"Stat": "StatCard_Stat__1-ndi",
	"Value": "StatCard_Value__2KWCk",
	"Label": "StatCard_Label__NxeZ2"
};