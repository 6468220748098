import React, { useReducer, useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import classes from './Carousel.module.scss';
import classNames from 'classnames/dedupe';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

let interval = null;

const Carousel = (props) => {
  const { width } = useWindowDimensions();

  const indexReducer = (state, action) => {
    if (action.itemIndex >= 0) {
      return action.itemIndex;
    }
    return (state + 1) % (action.noOfItems)
  };
  
  const [activeItemIndex, setActiveItemIndex] = useReducer(indexReducer, 0);
  let chevronWidth = 40;
  const tick = () => setActiveItemIndex({noOfItems: props.noOfItems, noOfCards: props.noOfCards});

  useEffect(() => {
    if (props.interval && interval === null) {
      interval = setInterval(tick, props.interval);
    }
  });
  
  useEffect(() => {
    return () => {
      clearInterval(interval);
    }
  }, []);

  let pagination = [];
  for (let index = 0; index < props.noOfItems; index++) {
    let bulletClass = classNames(classes.PaginationBullet);
    if (index === activeItemIndex) {
      bulletClass = classNames(classes.PaginationBullet, classes.PaginationBulletActive);
    }
    pagination.push(<span key={index} className={bulletClass} style={{left: '-54px'}} onClick={() => setActiveItemIndex({itemIndex: index}) } ></span>);
  }

  let noOfCards = props.noOfCards;
  let chevronOutside = true;
  if (width <= 767) {
    noOfCards = 1;
    chevronOutside = false;
  }

  let leftChevron = (
    <Fab size='small' aria-label='left'>
      <ChevronLeftIcon />
    </Fab>
  );
  let rightChevron = (
    <Fab size='small' aria-label='right'>
      <ChevronRightIcon />
    </Fab>
  );
  if (props.showChevron !== undefined && props.showChevron === false) {
    leftChevron = null;
    rightChevron = null;
    chevronOutside = false;
    chevronWidth = 0;
  }

  return (
    <div>
      <ItemsCarousel
        infiniteLoop
        requestToChangeActive={(index) => setActiveItemIndex({itemIndex: index})}
        activeItemIndex={activeItemIndex}
        numberOfCards={noOfCards}
        slidesToScroll={noOfCards}
        gutter={20}
        leftChevron={ leftChevron }
        rightChevron={ rightChevron }
        showSlither={false}
        firstAndLastGutter={false}
        outsideChevron={chevronOutside}
        chevronWidth={chevronWidth}
      >
        {props.children}
      </ItemsCarousel>
      { props.showPagination !== undefined && props.showPagination === false ? null : (
        <div className={classes.Pagination}>
          { pagination }
        </div>
      )}
    </div>
  );
}

export default Carousel;