import styled from 'styled-components'
import Button from '@material-ui/core/Button';

const PrimaryButton = styled(Button)`
  && {
    background-color: #003870;
    border-radius: 32px;
    min-width: 150px;
    padding: 15px 35px;
    color: #FFF;
  }

  &&:hover {
    background-color: #0055ab;
    color: #FFF;
  }

  &&:disabled {
    background-color: #CCCCCC !important;
    color: #666;
  }
`

export default PrimaryButton;