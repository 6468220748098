import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  && {
    color: #283342;
  }
  &&.MuiCheckbox-colorSecondary.Mui-checked {
    color: #283342;
  }
`

const CheckboxInput = props => {
  return (
    <FormControlLabel style={{color: '#283342', ...props.style}} control={
      <StyledCheckbox name={props.name} onChange={props.onChange} {...props} />
    } label={props.label} />
  )
}

export default CheckboxInput;