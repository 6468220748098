import styled from 'styled-components'
import Link from '@material-ui/core/Link';
import variable from '../../variables.js';

const MenuItem = styled(Link)`
  && {
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 120px;
    min-height: 40px;
    color: ${variable.headerColor};
    font-weight: 500;
  }
`

export default MenuItem;