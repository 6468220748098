import React from 'react'
import Chip from '@material-ui/core/Chip';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import classes from './Card.module.scss'
import styled from 'styled-components'
import logo from '../../assets/images/geologo.svg';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';

const StyledChip = styled(Chip)`
  && {
    background-color: #003870;
    color: #FFF;
    height: 18px;
  }
`

const Card = props => {
  let styleCard = {}
  let styleImg = {}
  let styleInformation = {}
  if (props.popup) {
    styleCard = {
      height: '270px',
      gridTemplateColumns: '1fr',
      boxShadow: 'initial',
      margin: '0',
      padding: '0',
      width: '300px'
    }

    styleInformation = {
      paddingLeft: '0'
    }

    styleImg = {
      width: '240px'
    }
  }
  return (
    <div className={classes.Card} style={styleCard}>
      <div className={classes.Logo}>
        <img style={styleImg} src={props.data.logo ? props.data.logo.includes('base64') ? props.data.logo : `/images/logos/${props.data.logo}` : logo} alt='logo' />
      </div>
      <div className={classes.Information} style={styleInformation}>
        <div className={classes.Header}>
          <div className={classes.Title}>{props.data.name}</div>
          <div className={classes.Tag}>
            <StyledChip size="small" label={props.data.type} style={isMobile ? {fontSize: '10px'} : {}} />
          </div>
        </div>
        <div className={classes.Website}><a href={props.data.website} target="_blank">{props.data.website}</a></div>
        <div className={classes.Address}>{props.data.description}</div>
        <div className={classes.More}>
          <span 
            className={classes.Clickable} 
            onClick={() => props.onShowMore(props.data)}>
              <FormattedMessage id="app.startup.card.see.more" defaultMessage="" />
          </span>
          <ArrowRightAltIcon className={classes.Clickable} onClick={() => props.onShowMore(props.data)} />
        </div>
      </div>
    </div>
  )
}

export default Card;