import decode from 'jwt-decode';

const TOKEN_KEY = 'gp-tk';
const TOKEN_USER_NAME = 'gp-tk-user-name';

const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

const getToken = () => localStorage.getItem(TOKEN_KEY);

const getUserName = () => localStorage.getItem(TOKEN_USER_NAME);


const getUserLogged = token => {
  try {
    const decoded = decode(token);
    return decoded.name;
  } catch (err) {
    return "";
  }
};

const getRoles = () => {
  try {
    const decoded = decode(getToken());
    return decoded.roles;
  } catch (err) {
    return [];
  }
};

const getEmail = () => {
  try {
    const decoded = decode(getToken());
    return decoded.email;
  } catch (err) {
    return null;
  }
}

const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
  const userName = getUserLogged(token);
  localStorage.setItem(TOKEN_USER_NAME, userName);
};

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_USER_NAME);
};

// Additional methods

const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
};

const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
};

const _checkStatus = response => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

export default {
  isAuthenticated,
  getToken,
  login,
  getUserName,
  getRoles,
  getEmail,
  logout,
  loggedIn,
  isTokenExpired,
  _checkStatus
}