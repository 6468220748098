import React from 'react';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import RoomIcon from '@material-ui/icons/Room';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from '../../containers/Filters';
import List from '../../containers/List';
import Layout from '../../components/Layout/Layout';
import SearchBox from '../../components/Input/SearchInput';
import Card from '../../components/Cards/Card';
import PrimaryButton from '../../components/Button/PrimaryButton';
import withSizes from 'react-sizes';
import classes from './GeoMap.module.scss';
import StartupServices from '../../services/startups.services';
import { Map, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-markercluster";
import { v4 as uuidv4 } from 'uuid';
import { filter, filterAll, getFilters } from '../../utils/filters';
import * as QueryString from 'query-string';
import Details from '../../containers/Details';
import { FormattedMessage, injectIntl } from 'react-intl';

const modes = {
  MAP: 'MAP',
  LIST: 'LIST'
}

const GeoMap = props => {
  const { intl } = props;
  const queries = QueryString.parse(props.location.search)

  const mapRef = React.useRef()
  const fullSearchBoxRef = React.useRef(null)
  const resultSearchBoxRef = React.useRef(null)

  const [startups, setStartups] = React.useState([])
  const [markers, setMarkers] = React.useState([])
  const [showBackdrop, setShowBackdrop] = React.useState(false)
  const [keyword, setKeyword] = React.useState(null)
  const [mapKey, setMapKey] = React.useState(uuidv4())
  const [currentFilters, setCurrentFilters] = React.useState(queries['type'] ? { type: queries['type'] } : {})
  const [filters, setFilters] = React.useState({})
  const [fixedFilters, setFixedFilters] = React.useState(queries['type'] ? { type: queries['type'] } : {})
  const [mode, setMode] = React.useState(queries['mode'] ? queries['mode'] : modes.MAP)
  const [showDetails, setShowDetails] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(false)
  const [locations, setLocations] = React.useState([])
  const [showFilters, setShowFilters] = React.useState(false)
  const [hideBanner, setHideBanner] = React.useState(false)

  const [mapViewport, setMapViewport] = React.useState(4)
  const [mapZoom, setMapZoom] = React.useState(4)
  const [mapCenter, setMapCenter] = React.useState({
    lat: -21.0002179,
    lng: -61.0006565
  })
  const labels = {
    hasFounderYoung: intl.formatMessage({ id: 'app.map.filters.hasyoung', defaultMessage: '' }),
    hasWomen: intl.formatMessage({ id: 'app.map.filters.haswomen', defaultMessage: 'Women' }),
    hasRural: intl.formatMessage({ id: 'app.map.filters.hasrural', defaultMessage: 'Rural' })
  }

  React.useEffect(() => {
    // setShowBackdrop(true)
    StartupServices.data()
      .then(result => {
        setStartups(result)
        const filtered = filterAll(result, currentFilters)
        setFilters(getFilters(filtered, ['country', 'type', 'category', 'services', 'hasFounderYoung', 'hasWomen', 'hasRural']))
        setMarkers(filtered.filter(m => m.lat))
        // setShowBackdrop(false)
        return StartupServices.getAvailableLocations()
      })
      .then(locations => {
        setLocations(locations)
      })
      .catch(err => {
        console.log(err)
      })
  }, []);

  const onSearch = () => {

    if (props.isMobile && fullSearchBoxRef.current.value.length === 0) {
      return
    }

    if (!props.isMobile && resultSearchBoxRef.current.value.length === 0) {
      return
    }

    const searchKeyBox = !props.isMobile ? resultSearchBoxRef.current : fullSearchBoxRef.current
    const searchKey = searchKeyBox.value

    if (!locations.includes(searchKey.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
      if (props.isMobile)
        fullSearchBoxRef.current.value = ""
      else
        resultSearchBoxRef.current.value = ""
      return
    }

    setShowBackdrop(true)
    setKeyword(searchKey)
    setMapKey(uuidv4())
    addFilter('location', searchKey)
    searchKeyBox.value = ''
    setShowBackdrop(false)
  }

  const onChange = (event) => {
    setKeyword(event.target.value)
  }

  const onShowMore = (selected) => {
    // setTimeout(function(){ 
    //   mapRef.current.contextValue.map.invalidateSize()
    //   mapRef.current.contextValue.map.setView([selected.lat, selected.lng])
    // }, 1000);
    setShowDetails(true)
    setSelectedItem(selected)
  }

  const removeFilter = (key, value = undefined) => {
    if (fixedFilters[key] && value === fixedFilters[key]) {
      return
    }
    setShowBackdrop(true)
    const _filters = { ...currentFilters }
    if (key !== 'type') {
      if (Array.isArray(_filters[key])) {
        _filters[key].splice(_filters[key].indexOf(value), 1)
        if (_filters[key].length === 0) {
          delete _filters[key]
        }
      } else {
        delete _filters[key]
      }
    } else {
      delete _filters[key]
    }
    setCurrentFilters(_filters)
    const result = filterAll(startups, _filters)
    setMarkers(result.filter(m => m.lat))
    if (key === 'type') {
      setFilters(getFilters(result, ['country', 'type', 'category', 'services', 'hasFounderYoung', 'hasWomen', 'hasRural']))
    }
    if (key === 'location') {
      setKeyword(null)
    }
    setShowBackdrop(false)
  }

  const addFilter = (key, value) => {
    if (value === 'Si') value = true
    if (value === 'No') value = false
    setShowBackdrop(true)
    const _filters = { ...currentFilters }
    if (key !== 'type') {
      if (Array.isArray(_filters[key])) {
        _filters[key].push(value)
      } else {
        _filters[key] = [value]
      }
    } else {
      _filters[key] = value
    }
    setCurrentFilters(_filters)
    const result = filterAll(startups, _filters)
    setMarkers(result.filter(m => m.lat))
    if (key === 'type') {
      setFilters(getFilters(result, ['country', 'type', 'category', 'services', 'hasFounderYoung', 'hasWomen', 'hasRural']))
    }
    setShowBackdrop(false)
  }

  let containerStyle = {}
  if (showDetails && !showDetails) {
    containerStyle = {
      gridTemplateColumns: '60% 40%'
    }
  }
  if (props.isMobile && (mode === modes.LIST || showFilters) && !showDetails) {
    containerStyle = {
      ...containerStyle,
      gridTemplateRows: '50px auto'
    }
  }

  return (
    <Layout hideFooter={true} showBackdrop={showBackdrop} hideBanner={true}>
      <div className={classes.Container} style={containerStyle}>
        {
          !showDetails && !props.isMobile ?
            <div className={classes.Results}>
              <SearchBox forwardedRef={resultSearchBoxRef} onSearch={onSearch} defaultValue={keyword} placeholder={intl.formatMessage({ id: 'app.map.input.search.hover', defaultMessage: '' })} />
              <div className={classes.ResultCounter}><b>{markers.length}</b>&nbsp;
              <FormattedMessage id="app.map.result.search.counter" defaultMessage="" />
              </div>
              {keyword ? <h2><span>en</span> {keyword}</h2> : null}
              <div className={classes.CurrentFilters}>
                <div className={classes.Label}>
                  <FormattedMessage id="app.map.result.search.criterias.title" defaultMessage="" />
                </div>
                {
                  Object.keys(currentFilters).map((key, index) => {
                    const filters = Array.isArray(currentFilters[key]) ? currentFilters[key] : [currentFilters[key]]
                    const comp = []
                    for (let i = 0; i < filters.length; i++) {
                      comp.push(
                        <Chip
                          key={`${key}_${i}_tag`}
                          style={{ margin: '2px 5px' }}
                          size="small"
                          label={typeof filters[i] === 'boolean' ? `${labels[key]}: ${filters[i] ? 'Si' : 'No'}` : filters[i]}
                          onDelete={() => removeFilter(key, filters[i])} />
                      )
                    }
                    return comp
                  })
                }
              </div>
              <div className={classes.MapButton}>
                {mode === modes.LIST ?
                  <PrimaryButton onClick={() => setMode(modes.MAP)}>
                    <RoomIcon />&nbsp;
                  <FormattedMessage id="app.map.button.map" defaultMessage="" />
                  </PrimaryButton> :
                  <PrimaryButton onClick={() => setMode(modes.LIST)}>
                    <ListIcon />&nbsp;
                  <FormattedMessage id="app.map.button.list" defaultMessage="" />
                  </PrimaryButton>
                }
              </div>
              <div className={classes.Filters}>
                {
                  Object.keys(filters).map((key, index) => {
                    return (
                      <Filters
                        key={index}
                        show={3}
                        filterKey={key}
                        filter={filters[key]}
                        currentFilter={currentFilters[key]}
                        addFilter={addFilter}
                        removeFilter={removeFilter} />
                    )
                  })
                }
              </div>
            </div> :
            null
        }
        {
          props.isMobile ?
            <div className={classes.FloatFilters}>
              {!showFilters && !showDetails ?
                <div className={classes.MapButton}>
                  {mode === modes.LIST ?
                    <Fab size="medium" style={{ background: '#FFF' }} aria-label="map">
                      <RoomIcon onClick={() => setMode(modes.MAP)} />
                    </Fab> :
                    <Fab size="medium" style={{ background: '#FFF' }} aria-label="list">
                      <ListIcon onClick={() => setMode(modes.LIST)} />
                    </Fab>
                  }
                </div> : <div />
              }
              <div className={classes.FilterButton}>
                {!showDetails ?
                  <Fab size="medium" style={{ background: '#FFF' }} aria-label="filter">
                    {!showFilters ?
                      <FilterListIcon onClick={() => { setShowFilters(true); setHideBanner(true) }} /> :
                      <CloseIcon onClick={() => { setShowFilters(false); setHideBanner(false) }} />
                    }
                  </Fab> :
                  <Fab size="medium" style={{ background: '#FFF' }} aria-label="details">
                    <CloseIcon onClick={() => setShowDetails(false)} />
                  </Fab>
                }
              </div>
            </div> :
            null
        }
        {
          props.isMobile && showFilters ?
            <>
              {props.isMobile ? <div className={classes.DummySpace} /> : null}
              <div className={classes.Filters}>
                <SearchBox forwardedRef={fullSearchBoxRef} onSearch={onSearch} onChange={onChange} placeholder='nombre, región o país' />
                <div className={classes.ResultCounter}><b>{markers.length}</b>&nbsp;resultados</div>
                {keyword ? <h2><span>en</span> {keyword}</h2> : null}
                <div className={classes.CurrentFilters}>
                  <div className={classes.Label}>Criterios seleccionados</div>
                  {
                    Object.keys(currentFilters).map((key, index) => {
                      const filters = Array.isArray(currentFilters[key]) ? currentFilters[key] : [currentFilters[key]]
                      const comp = []
                      for (let i = 0; i < filters.length; i++) {
                        comp.push(
                          <Chip
                            key={`${key}_${i}_tag`}
                            style={{ margin: '2px 5px' }}
                            size="small"
                            label={filters[i]}
                            onDelete={() => removeFilter(key, filters[i])} />
                        )
                      }
                      return comp
                    })
                  }
                </div>
                {
                  Object.keys(filters).map((key, index) => {
                    return (
                      <Filters
                        key={index}
                        show={3}
                        filterKey={key}
                        filter={filters[key]}
                        currentFilter={currentFilters[key]}
                        addFilter={addFilter}
                        removeFilter={removeFilter} />
                    )
                  })
                }
                <br />
                <div style={{ display: 'grid', justifyItems: 'center' }}>
                  <PrimaryButton style={props.isMobile ? { fontSize: '12px' } : {}} onClick={() => setShowFilters(false)}>
                    Aplicar Filtros
                </PrimaryButton>
                </div>
              </div>
            </> :
            null
        }
        {
          showDetails ?
            <Details data={selectedItem} onClose={() => setShowDetails(false)} /> :
            null
        }
        {mode === modes.MAP && !showFilters ?
          <Map
            ref={mapRef}
            key={mapKey}
            center={mapCenter}
            zoom={mapZoom}
            viewport={mapViewport}
            zoomControl={false}
            style={{ zIndex: '0' }}
          >
            <ZoomControl position="bottomright" />
            <TileLayer
              url="https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=af2a4de894914f8082f84f6a8fb84c3e"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
            <MarkerClusterGroup>
              {
                markers.map((item, index) => {
                  if (!item.lat) {
                    return null
                  }
                  return (
                    <Marker
                      key={index}
                      position={{ lat: item.lat, lng: item.lng }} >
                      <Popup>
                        <Card key={index} onShowMore={(data) => onShowMore(data)} data={item} popup={true} />
                      </Popup>
                    </Marker>
                  )
                })
              }
            </MarkerClusterGroup>
          </Map> :
          null
        }
        {
          mode === modes.LIST && !showFilters ?
            <>
              {props.isMobile ? <div className={classes.DummySpace} /> : null}
              <List data={markers} onShowMore={onShowMore} />
            </> :
            null
        }
      </div>
    </Layout>
  )
}

const mapSizesToProps = ({ width, height }) => ({
  isMobile: width <= 767,
  width: width,
  height: height
})

export default injectIntl(withSizes(mapSizesToProps)(GeoMap));