import axios from 'axios';
import Servers from './servers.enum';
import Endpoints from './endpoints.enum';
import AuthenticationHelper from '../services/authentication';

axios.interceptors.request.use(request => {
  // log request
  request.headers['app-id'] =  'GP';
  request.headers.patch['Authorization'] = `Bearer ${AuthenticationHelper.getToken()}`;
  return request;
}, error => {
  // log error
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  // log request
  return response;
}, error => {
  // log error
  return Promise.reject(error);
});

const data = async (params) => {
  if (params === undefined) {
    params = ''
  }
  try {
    const res = await axios.get(Servers.SERVER + Endpoints.STARTUPS + `?${params}`);

    console.log("NUSS ------ ")
    const data = res.data.payload
    const result = data.map(item => {
      item.services = item.services ? item.services.split(',') : []
      item.services = item.services.map(item => item.trim())
      return item
    })
    return result;
  } catch (err) {
    console.log("NUSS", err)
    throw Error(err.response.data.status);
  }
}

const create = async (data) => {
  try {
    const res = await axios.post(Servers.SERVER + Endpoints.STARTUPS, data);
    return res.data.payload;
  } catch (err) {
    throw Error(err.response.data.status);
  }
}

const get = async (id) => {
  try {
    const res = await axios.get(Servers.SERVER + Endpoints.STARTUPS + `/${id}`);
    return res.data.payload;
  } catch (err) {
    throw Error(err.response.data.status);
  }
}

const stats = async () => {
  try {
    const res = await axios.get(Servers.SERVER + Endpoints.STATS);
    return res.data.payload;
  } catch (err) {
    throw Error(err.response.data.status);
  }
}

const update = async (id, data) => {
  try {
    const res = await axios.patch(Servers.SERVER + Endpoints.STARTUPS + `/${id}`, data);
    return res.data.payload;
  } catch (err) {
    throw Error(err.response.data.status);
  }
}

const getAvailableLocations = async () => {
  try {
    const res = await axios.get(Servers.SERVER + Endpoints.LOCATIONS);
    return res.data.payload;
  } catch (err) {
    throw Error(err.response.data.status)
  }
}

export default {
  data,
  create,
  get,
  stats,
  update,
  getAvailableLocations
}