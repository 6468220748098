import React from 'react';
import { Formik, Form } from 'formik';
import classes from './Login.module.scss';
import LoginSchema from './Login.validate'
import PrimaryButton from '../../components/Button/PrimaryButton';
import TextInput from '../../components/Input/TextInput';
import AuthServices from '../../services/auth.services'
import AuthenticationHelper from '../../services/authentication';

const Login = props => {

  const handleSubmit = (values, {
    setSubmitting
  }) => {
    AuthServices.login(values)
    // .then(AuthenticationHelper._checkStatus)
    .then(res => {
      AuthenticationHelper.login(res.access_token);
      setSubmitting(false);
      window.location.href = '/dashboard';
    })
    .catch(err => {
      console.log(err);
      setSubmitting(false);
    })
  }

  return (
    <div className={classes.Login}>
      <Formik 
        innerRef={props.forwardedRef}
        initialValues={{
          username: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}>
        { formProps => {
          return (
            <Form>
              <div className={classes.Container}>
                <div className={classes.Title}>
                  GeoPortal
                </div>
                <div className={classes.Body}>
                  <section>
                    <div className={classes.Title}></div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}></div>
                      <div>
                        <TextInput 
                          key='username' 
                          id='username' 
                          label='Email'
                          name='username'
                          placeholder='Ingrese su email'
                          inputProps={{type: 'email'}}
                          value={ formProps.values.username }
                          onChange={(event) => formProps.setFieldValue('username', event.target.value)}
                          error={ formProps.errors.username }
                          disabled={props.disabled} />
                      </div>
                    </div>
                    <div className={classes.FormElement}>
                      <div className={classes.Label}></div>
                      <div>
                        <TextInput 
                          key='password' 
                          id='password' 
                          label='Contraseña'
                          name='password'
                          placeholder='Ingrese su contraseña'
                          inputProps={{type: 'password'}}
                          value={ formProps.values.password }
                          onChange={(event) => formProps.setFieldValue('password', event.target.value)}
                          error={ formProps.errors.password }
                          disabled={props.disabled} />
                      </div>
                    </div>
                  </section>
                </div>
                <div className={classes.Footer}>
                  <PrimaryButton type='submit'>
                    Entrar
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  )
}

export default Login;