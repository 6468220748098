exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Navbar_Navbar__2q9tz {\n  justify-self: stretch;\n  align-self: stretch;\n  display: grid;\n  grid-template-columns: 100px auto;\n  grid-template-rows: 53px auto;\n  align-items: end;\n  min-height: 53px; }\n\n.Navbar_Menu__12K8x {\n  align-self: stretch;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: flex-end;\n  color: #FFFFFF; }\n\n.Navbar_MenuItems__2Nw-I {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: flex-end; }\n\n.Navbar_Logo__2iG0s img {\n  height: 50px; }\n\n.Navbar_LinkToggle__MuayH {\n  display: none; }\n\n.Navbar_ToggleHide__3Zaf7 {\n  display: none; }\n\n.Navbar_Lang__36co6 {\n  display: none; }\n\n/* Mobile */\n@media only screen and (max-width: 767px) {\n  .Navbar_LinkToggle__MuayH {\n    display: grid;\n    justify-self: end;\n    align-self: center;\n    cursor: pointer;\n    color: #003870; }\n  .Navbar_Lang__36co6 {\n    display: grid;\n    justify-self: stretch;\n    align-self: stretch;\n    padding-right: 20px; }\n  .Navbar_ToggleShow__2Ekjy {\n    display: flex !important; }\n  .Navbar_MenuItems__2Nw-I {\n    display: none;\n    flex-direction: column;\n    border-top: 1px solid #FFFFFF;\n    grid-column: 1 / span 2; } }\n", ""]);

// Exports
exports.locals = {
	"Navbar": "Navbar_Navbar__2q9tz",
	"Menu": "Navbar_Menu__12K8x",
	"MenuItems": "Navbar_MenuItems__2Nw-I",
	"Logo": "Navbar_Logo__2iG0s",
	"LinkToggle": "Navbar_LinkToggle__MuayH",
	"ToggleHide": "Navbar_ToggleHide__3Zaf7",
	"Lang": "Navbar_Lang__36co6",
	"ToggleShow": "Navbar_ToggleShow__2Ekjy"
};