import React from 'react';
import Accordion from '../components/Accordion/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckboxInput from '../components/Input/CheckboxInput';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { FormattedMessage, injectIntl } from 'react-intl';

const linkStyle = {
  fontWeight: '600',
  cursor: 'pointer',
  color: '#003870',
  display: 'grid',
  gridTemplateColumns: '110px auto',
  alignItems: 'center'
}

const Filters = (props) => {
  const { intl } = props;
  const [show, setShow] = React.useState(false);

  const labels = {
    country: intl.formatMessage({ id: 'app.map.filters.country', defaultMessage: '' }),
    type: intl.formatMessage({ id: 'app.map.filters.type', defaultMessage: '' }),
    category: intl.formatMessage({ id: 'app.map.filters.category', defaultMessage: '' }),
    services: intl.formatMessage({ id: 'app.map.filters.service', defaultMessage: '' }),
    hasFounderYoung: intl.formatMessage({ id: 'app.map.filters.hasyoung', defaultMessage: '' }),
    hasWomen: intl.formatMessage({ id: 'app.map.filters.haswomen', defaultMessage: '' }),
    hasRural: intl.formatMessage({ id: 'app.map.filters.hasrural', defaultMessage: '' })
  }

  const showMore = (index) => {
    if (props.show === index && !show) {
      return <div style={linkStyle} onClick={() => setShow(!show)} >
        <span>
          <FormattedMessage id="app.map.filters.link.more" defaultMessage="" />
        </span>
        <ArrowDownwardIcon />
      </div>
    }
    return null;
  }

  const showLess = (index) => {
    if (show) {
      return <div style={linkStyle} onClick={() => setShow(!show)} >
        <span>
          <FormattedMessage id="app.map.filters.link.less" defaultMessage="" />
        </span>
        <ArrowUpwardIcon />
      </div>
    }
    return null;
  }

  return (
    <Accordion>
      <AccordionSummary
        id={`${props.filterKey}`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography><b>{labels[props.filterKey]}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'grid' }}>
          {
            props.filter.map((value, index) => {
              const label = value
              if (value === 'Si' || value === 'No') {
                value = value === 'Si' ? true : false
              }
              let checked = false;
              if (props.currentFilter) {
                if (Array.isArray(props.currentFilter) && props.currentFilter.includes(value)) {
                  checked = true
                } else {
                  checked = value === props.currentFilter
                }
              }

              return (
                <>
                  {showMore(index)}
                  <CheckboxInput
                    key={`${props.filterKey}_value_${index}`}
                    style={{ display: !show && index >= props.show ? 'none' : 'grid', gridTemplateColumns: '42px auto', padding: '5px 0' }}
                    checked={checked}
                    onChange={(event) => {
                      if (event.target.checked)
                        props.addFilter(props.filterKey, value)
                      else
                        props.removeFilter(props.filterKey, value)
                    }}
                    name={props.filterKey}
                    label={intl.formatMessage({ id: `app.map.filters.options.${label}`, defaultMessage: label })} />
                </>
              )
            })
          }
          {showLess()}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default injectIntl(Filters);