import React from 'react';
import classes from './List.module.scss';
import { getPage } from '../utils/filters';
import { FormattedMessage } from 'react-intl';
import Card from '../components/Cards/Card';

const List = (props) => {
  const [page, setPage] = React.useState(0);

  return (
    <div className={classes.List}>
      <div>
        {
          getPage(props.data, page).map((item, index) => <Card key={index} onShowMore={(data) => props.onShowMore(data)} data={item} />)
        }
      </div>
      <div className={classes.Footer}>
        <div style={{justifySelf: 'left', cursor: 'pointer'}} onClick={() => { if ((page-1) < 0) {return} setPage(page - 1) } }>
          <FormattedMessage id="app.dashboard.previous" defaultMessage="" />
        </div>
        <div style={{justifySelf: 'right', cursor: 'pointer'}} onClick={() => setPage(page + 1)}>
          <FormattedMessage id="app.dashboard.next" defaultMessage="" />
        </div>
      </div>
    </div>
  )
}

export default List;