import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthenticationHelper from '../../services/authentication';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route 
      { ...rest }
      render={props => AuthenticationHelper.loggedIn() && isAuthorized(roles) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location }}} />
      )}
    />
  )
}

const isAuthorized = (allowRoles) => {
  if (!allowRoles || allowRoles.length === 0) {
    return true;
  }

  let authorized = false;
  const userRoles = AuthenticationHelper.getRoles();
  if (userRoles) {
    for (var i = 0; i < userRoles.length; i++) {
      if (allowRoles.includes(userRoles[i])) {
        authorized = true;
        break;
      }
    }
  }
  return authorized;
}

export default PrivateRoute;