import axios from 'axios';
import Servers from './servers.enum';
import Endpoints from './endpoints.enum';

axios.interceptors.request.use(request => {
  // log request
  request.headers['app-id'] =  'GP';
  return request;
}, error => {
  // log error
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  // log request
  return response;
}, error => {
  // log error
  return Promise.reject(error);
});

const login = async (data) => {
  try {
    const res = await axios.post(Servers.SERVER + Endpoints.LOGIN, data);
    return res.data.payload;
  } catch (err) {
    console.log(err);
    //throw Error(err.response ?? err.response.data.status);
  }
}

export default {
  login
}