import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const SelectInput = (props) => {
  const isError = props.error ? true : false
  return (
    <FormControl style={{minWidth: '300px'}} disabled={props.disabled}>
      <InputLabel style={{ width: '133.33%', fontWeight: '700', fontSize: '16px', lineHeight: '14px', color: '#003870' }} htmlFor={props.id}>{ props.label }</InputLabel>
      <Select
        id={props.id}
        error={ isError }
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: props.name
        }}
        aria-describedby={`${props.id}-error`}
        native
      >
      { 
        props.values.map((item, index) => (
          <option key={index} value={item.key}>{ item.value }</option>
        ))
      }
      </Select>
      { isError ? <FormHelperText id={`${props.id}-error`} error={ isError }>{props.error}</FormHelperText> : null }
    </FormControl>
  )
}

export default SelectInput;