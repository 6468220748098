import React, { Fragment, useState, useContext } from 'react';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Select from '../Input/Select';
import classes from './Navbar.module.scss';
import classNames from 'classnames/dedupe';
import MenuItem from '../MenuItem/MenuItem';
import SocialItem from '../MenuItem/SocialItem';
import { GlobalConsumer } from '../../context/GlobalContext';
import { isMobile } from 'react-device-detect';
import { Context } from '../Intl/Intl';

const Navbar = (props) => {
  const intlContext = useContext(Context);
  const [toggled, setToggled] = useState(false);

  const menuClasses = !toggled ? classNames(classes.MenuItems, classes.ToggleHide) : classNames(classes.MenuItems, classes.ToggleShow);
  const brandStyle = {
    position: 'absolute',
    left: '50%',
    top: '5px',
    transform: 'translate(-50%, 0)'
  }

  return (
    <div className={classes.Navbar}>
      <GlobalConsumer>
        { context => (
          <Fragment>
            <div className={classes.Logo}>
              <a href='/' style={props.centerBrand ? brandStyle : null} ><img src={context.header.logo} alt="logo" /></a>
            </div>
            <div className={classes.Menu}>
              { 
                context.header.socialitems && context.header.socialitems.length > 0 && window.location.href.includes('/dashboard') ? 
                <div className={null}>
                  { 
                    context.header.socialitems.map(item => {
                      return (
                        <SocialItem key={item.id} component="button" variant="body2" onClick={item.action}>
                          { item.label }
                        </SocialItem>
                      );
                    })
                  }
                </div> : 
                null
              }
              <div className={classes.Lang}>
                <Select
                  id='lang'
                  value={intlContext.language}
                  // variant='outlined'
                  onChange={intlContext.selectLanguage}
                  inputProps={{
                    name: 'lang'
                  }}
                  native
                >
                  <option key='1' value='es'>es</option>
                  <option key='2' value='en'>en</option>
                </Select>
              </div>
              { 
                context.header.menuitems && context.header.menuitems.length > 0 ?
                <div 
                  className={classes.LinkToggle}
                  onClick={() => setToggled(!toggled)}>
                  <DehazeIcon />
                </div> : 
                null
              }
              <div className={classes.MenuItems}>
                <Select
                  id='lang'
                  value={intlContext.language}
                  // variant='outlined'
                  onChange={intlContext.selectLanguage}
                  inputProps={{
                    name: 'lang'
                  }}
                  native
                >
                  <option key='1' value='es'>es</option>
                  <option key='2' value='en'>en</option>
                </Select>
                { window.location.href.includes('/dashboard') ? null :
                  context.header.menuitems.map(item => {
                    if (isMobile && (item.desktopOnly === undefined || item.desktopOnly === null || item.desktopOnly === false)) {
                      return (
                        <MenuItem key={item.id} component="button" variant="body2" onClick={item.action}>
                          { item.label }
                        </MenuItem>
                      );
                    } else 
                    if (!isMobile && (item.mobileOnly === undefined || item.mobileOnly === null || item.mobileOnly === false)) {
                      return (
                        <MenuItem key={item.id} component="button" variant="body2" onClick={item.action}>
                          { item.label }
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })
                }
              </div>
            </div>
            <div className={menuClasses}>
              { window.location.href.includes('/dashboard') ? null :
                context.header.menuitems.map(item => {
                  return (
                    <MenuItem key={item.id} component="button" variant="body2" onClick={item.action}>
                      { item.label }
                    </MenuItem>
                  );
                })
              }
            </div>
          </Fragment>
        ) }
      </GlobalConsumer>
    </div>
  )
}

export default Navbar;