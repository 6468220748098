exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.SecundaryLayout_Container__2iG5W {\n  display: grid;\n  grid-template-columns: 36% auto;\n  height: 100vh; }\n  @media only screen and (max-width: 767px) {\n    .SecundaryLayout_Container__2iG5W {\n      grid-template-columns: 1fr;\n      width: 100%; } }\n  .SecundaryLayout_Container__2iG5W .SecundaryLayout_Image__32Of_ {\n    display: grid;\n    align-items: center; }\n    @media only screen and (max-width: 767px) {\n      .SecundaryLayout_Container__2iG5W .SecundaryLayout_Image__32Of_ {\n        display: none; } }\n    .SecundaryLayout_Container__2iG5W .SecundaryLayout_Image__32Of_ img {\n      width: 100%; }\n  .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw {\n    overflow-y: auto;\n    padding: 40px 60px;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: 40px auto; }\n    @media only screen and (max-width: 767px) {\n      .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw {\n        padding: 30px 40px; } }\n    .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw .SecundaryLayout_Header__ci6l9 {\n      display: grid;\n      grid-template-columns: 150px auto 150px; }\n      .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw .SecundaryLayout_Header__ci6l9 .SecundaryLayout_Return__27IC3 {\n        display: grid;\n        grid-template-columns: 30px auto;\n        align-items: center;\n        cursor: pointer; }\n      .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw .SecundaryLayout_Header__ci6l9 .SecundaryLayout_Lang__3UtTo {\n        justify-self: right; }\n    .SecundaryLayout_Container__2iG5W .SecundaryLayout_Main__3CIFw .SecundaryLayout_Body__3CFwa {\n      display: grid;\n      height: 100vh; }\n", ""]);

// Exports
exports.locals = {
	"Container": "SecundaryLayout_Container__2iG5W",
	"Image": "SecundaryLayout_Image__32Of_",
	"Main": "SecundaryLayout_Main__3CIFw",
	"Header": "SecundaryLayout_Header__ci6l9",
	"Return": "SecundaryLayout_Return__27IC3",
	"Lang": "SecundaryLayout_Lang__3UtTo",
	"Body": "SecundaryLayout_Body__3CFwa"
};