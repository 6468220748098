import React from 'react';
import classes from './FlotingBanner.module.scss';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { FormattedMessage } from 'react-intl';

const FlotingBanner = props => {
  return (
    <div className={classes.FlotingBanner} onClick={() => window.location.href = '/register'}>
      <div className={classes.Info}>
        <div className={classes.Title}>
          <FormattedMessage id="app.floating.banner.title" defaultMessage="" />
        </div>
        <div className={classes.Subtitle}>
          <FormattedMessage id="app.floating.banner.subtitle" defaultMessage="" />
        </div>
      </div>
      <div className={classes.Icon}>
        <ArrowRightAltIcon fontSize="large" />
      </div>
    </div>
  )
}

export default FlotingBanner;