import styled from 'styled-components'
import Link from '@material-ui/core/Link';

const SocialItem = styled(Link)`
  && {
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 50px;
    min-height: 40px;
    color: #2E384D;
  }
`

export default SocialItem;