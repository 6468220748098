import React, { Fragment } from 'react';
import classes from './Modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Backdrop from './Backdrop';
import { ScreenSize } from '../../utils/screen';

const Modal = (props) => {
  let modalWidth = ScreenSize.modalWidth;
  if (props.width) {
    modalWidth = props.width;
  }
  let modalMarginLeft = modalWidth / 2;
  if (window.innerWidth <= ScreenSize.mobileWidth) {
    modalWidth = window.innerWidth;
    modalMarginLeft = 0;
  }
  return (
    <Fragment>
      <Backdrop show={props.show} onModalClose={props.onModalClose} />
      { props.show ? <div className={classes.Close} onClick={props.onModalClose}><FontAwesomeIcon icon={faTimes} /></div> : null }
      <div 
        className={classes.Modal}
        style={{
          transform: props.show ? 'translateX(0)' : 'translateX(-100wh)',
          opacity: props.show ? '1' : '0',
          width: modalWidth + 'px',
          marginLeft: '-' + modalMarginLeft + 'px',
          zIndex: props.show ? '500' : '-500',
        }}
        >
        { props.children } 
      </div>
    </Fragment>
  );
}

export default Modal;