exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.Login_Login__2rkDV {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 1fr;\n  align-items: center;\n  justify-items: center;\n  height: 100vh;\n  width: 100vw; }\n  .Login_Login__2rkDV .Login_Container__2HemX {\n    width: 100%;\n    padding: 20px 0; }\n    .Login_Login__2rkDV .Login_Container__2HemX .Login_Title__j9Sh4 {\n      font-weight: 400;\n      font-size: 24px;\n      line-height: 28px; }\n    .Login_Login__2rkDV .Login_Container__2HemX .Login_Body__r4F_f section {\n      padding: 20px 0;\n      display: grid;\n      grid-template-columns: 1fr; }\n      .Login_Login__2rkDV .Login_Container__2HemX .Login_Body__r4F_f section .Login_Title__j9Sh4 {\n        font-weight: 700;\n        font-size: 18px;\n        line-height: 24px;\n        padding: 10px 0; }\n    .Login_Login__2rkDV .Login_Container__2HemX .Login_Body__r4F_f .Login_FormElement__1Ptzt {\n      padding: 10px 0; }\n      .Login_Login__2rkDV .Login_Container__2HemX .Login_Body__r4F_f .Login_FormElement__1Ptzt .Login_Label__34_-R {\n        font-weight: 700;\n        font-size: 11px;\n        line-height: 14px; }\n      .Login_Login__2rkDV .Login_Container__2HemX .Login_Body__r4F_f .Login_FormElement__1Ptzt .Login_Error__1fuf4 {\n        color: red; }\n    .Login_Login__2rkDV .Login_Container__2HemX .Login_Footer__1YgQK {\n      display: grid;\n      justify-items: right; }\n", ""]);

// Exports
exports.locals = {
	"Login": "Login_Login__2rkDV",
	"Container": "Login_Container__2HemX",
	"Title": "Login_Title__j9Sh4",
	"Body": "Login_Body__r4F_f",
	"FormElement": "Login_FormElement__1Ptzt",
	"Label": "Login_Label__34_-R",
	"Error": "Login_Error__1fuf4",
	"Footer": "Login_Footer__1YgQK"
};