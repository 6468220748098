import React from 'react';
import RadioButton from '../Button/RadioButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const RadioInput = (props) => {
  const isError = props.error ? true : false
  return (
    <FormControl style={props.style} disabled={props.disabled}>
      <InputLabel style={{ width: '180px' }} shrink={true} htmlFor={props.id}>{ props.label }</InputLabel>
      <RadioGroup 
        id={ props.id }
        style={{marginTop: '16px'}}
        aria-label={ props.name }
        name={ props.name }
        value={ props.value }
        onChange={ props.onChange }
        aria-describedby={`${props.id}-error`}
        row>
        {
          props.values.map((item, index) => (
            <FormControlLabel key={index} value={item.key} control={<RadioButton />} label={item.value} />
          ))
        }
      </RadioGroup>
      { isError ? <FormHelperText id={`${props.id}-error`} error={ isError }>{props.error}</FormHelperText> : null }
    </FormControl>
  )
}

export default RadioInput;