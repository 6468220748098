import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const TextInput = (props) => {
  const isError = props.error ? true : false
  return (
    <FormControl style={{minWidth: '300px'}} disabled={props.disabled}>
      <InputLabel style={{ fontWeight: '700', fontSize: '16px', lineHeight: '14px', color: '#003870' }} shrink={true} htmlFor={props.id}>{ props.label }</InputLabel>
      <Input
        id={ props.id }
        name={ props.name }
        value={ props.value }
        onChange={ props.onChange }
        onBlur={ props.onBlur }
        aria-describedby={`${props.id}-error`}
        placeholder={props.placeholder}
        inputProps={props.inputProps}
        error={ isError }
        variant="outlined"
      />
      { isError ? <FormHelperText id={`${props.id}-error`} error={ isError }>{props.error}</FormHelperText> : null }
    </FormControl>
  )
}

export default TextInput;