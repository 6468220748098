exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Global */\n/* MOBILE */\n/* Modal */\n/* Header - Navbar */\n/* Footer */\n.SearchInput_SearchBox__2xyo3 {\n  padding: 2px 4px !important;\n  display: flex !important;\n  align-items: center !important;\n  width: 400 !important;\n  border-radius: 25px !important;\n  /* Mobile */ }\n  @media only screen and (max-width: 767px) {\n    .SearchInput_SearchBox__2xyo3 {\n      width: 100% !important; } }\n\n.SearchInput_Input__2dTIa {\n  margin-left: 8px !important;\n  flex: 1 1 !important; }\n\n.SearchInput_Icon__2jJAI {\n  padding: 6px 10px !important; }\n\n.SearchInput_Button__1l9q- {\n  border-radius: 25px !important;\n  background-color: #003870 !important;\n  padding: 6px 25px !important; }\n", ""]);

// Exports
exports.locals = {
	"SearchBox": "SearchInput_SearchBox__2xyo3",
	"Input": "SearchInput_Input__2dTIa",
	"Icon": "SearchInput_Icon__2jJAI",
	"Button": "SearchInput_Button__1l9q-"
};