import React from 'react';
import classes from './About.module.scss';
import Layout from '../../components/Layout/Layout';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const About = props => {
  return (
    <Layout showBackdrop={false} hideBanner={true}>
      <div className={classes.About}>
        <div className={classes.Image}>
          <img src='/images/about-us.png' alt="GeoPortal" />
        </div>
        <div className={classes.Text}>
          <div className={classes.Title}>
            <FormattedMessage id="app.about.title" defaultMessage="" />
          </div>
          <div className={classes.Content}>
          <p>
            <FormattedMessage id="app.about.paragraph.1" defaultMessage="" />
          </p>
          <p>
            <FormattedMessage id="app.about.paragraph.2" defaultMessage="" />
          </p>
          <p>
            <FormattedHTMLMessage id="app.about.paragraph.3" defaultMessage="" />
          </p>
          </div>
          <div className={classes.Footer}>
            <FormattedHTMLMessage id="app.about.footer" defaultMessage="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About;